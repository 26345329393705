@import '../../Mixins.scss';
@import '../../components/LeaderboardRanking.scss';
@import '../../Colours.module.scss';

.LeaderboardPage {
  @include central-graph-grid-layout;

  .ContentPanel {
    @include card-style;
    grid-row: 3;
    grid-column: 1 / span 12;
    margin: 8px 16px;

    .ScopeSelector {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 64px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;

      &.Three {
        grid-template-columns: repeat(3, 1fr);
      }

      .Scope {
        text-align: center;
        padding: 24px 0;
        cursor: pointer;

        &.Inactive {
          background: $grey-1;
        }
      }
    }

    .LearnMore {
      border: 1px solid $off-white-1;
      box-sizing: border-box;
      border-radius: 4px;
      height: 72px;
      margin: 20px 32px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 14px;
      }

      button {
        @include learnmore-button;
      }
    }

    .PagingSelector {
      display: flex;
      flex-direction: row;
      margin: 32px 32px 8px 32px;
      font-size: 10px;
      font-weight: 700;
      color: $grey-4;

      .Title {
        margin-right: 16px;
      }

      .PagingSelectorItem {
        cursor: pointer;
        text-transform: uppercase;

        &:not(:last-child):after {
          margin: 0 8px;
          content: '|';
        }

        &.Selected,
        &:hover {
          color: $black;
        }
      }
    }

    .LeaderboardList {
      @include card-style;
      margin: 16px 32px 32px 32px;
      z-index: 0;

      .LeaderboardRowHighlight {
        border-radius: 4px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.1);
        margin: 0 -5px;
        padding: 0 5px;
        position: relative;
        z-index: 1;
        background-color: $white;

        .LeaderboardListItem {
          background-color: $white;
        }
      }

      .LeaderboardListRow {
        display: flex;

        &.isSelected {
          border-radius: 4px;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.1);
          margin: 0 -5px;
          padding: 0 5px;
          position: relative;
          z-index: 1;
        }

        .LeaderboardListItemTooltip {
          .tooltip__body {
            text-align: center;
            white-space: pre-wrap;
            max-width: 31vw;
          }
        }

        &:nth-child(2n) {
          background-color: $off-white-6;
        }

        &:nth-child(2n-1) {
          background: white;
        }

        .LeaderboardListItem:nth-child(1) {
          flex: 1 1 0;
        }

        .LeaderboardListItem:nth-child(2) {
          flex: 2 1 0;
        }

        .LeaderboardListItem:nth-child(3) {
          flex: 3 1 0;
        }

        .LeaderboardListItem:nth-child(4) {
          flex: 1 1 0;
        }

        .LeaderboardListItem:nth-child(5) {
          flex: 1 1 0;
        }

        .LeaderboardListItem:nth-child(6) {
          flex: 1 1 0;
        }

        .LeaderboardListItem:nth-child(7) {
          flex: 1 1 0;
        }

        .LeaderboardHeader {
          font-size: 10px !important;
          font-weight: 700;
          text-align: center;
          border-bottom: 1px solid $off-white-1;
          padding: 16px 0;

          span {
            text-transform: uppercase;
          }

          &:nth-child(3) {
            text-align: left;
          }

          &.isSortable {
            cursor: pointer;
            color: $grey-4;

            &:hover {
              color: $black;
            }
          }

          &.isActiveSorting {
            color: $black;
          }

          .SortSymbol {
            margin-left: 4px;
            font-size: 6px;
            vertical-align: middle;
          }
        }

        .LeaderboardListItem {
          font-size: 14px;
          text-align: center;
          padding: 16px 0;

          &.Left {
            text-align: left;
          }

          span.brackets {
            color: $grey-5;
          }

          .MissingDataIcon > span {
            display: inline-block;
            width: 16px;
          }

          .MoreInfoButton {
            background: $grey-1;
            cursor: pointer;
            float: right;
            margin-right: 20px;
            height: 24px;
            width: 24px;
            border-radius: 16px;

            svg {
              margin-top: 6px;
              width: 12px;
            }

            &:hover {
              background: $off-white-2;
            }
          }

          .WatchlistToggle {
            cursor: pointer;
            height: 24px;

            & > span {
              height: 24px;
              overflow: hidden;
            }

            .InWatchlistIcon {
              display: inline-block;
              height: 24px;
              width: 24px;
              background-image: url('../../images/Icons/WatchlistAdded.svg');
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: contain;
              vertical-align: middle;

              &:hover {
                background-image: url('../../images/Icons/WatchlistRemove.svg');
              }
            }

            &.Right {
              float: right;
              margin-right: 16px;
            }

            &:hover circle {
              fill: $off-white-2;
            }
          }

          @include leaderboard-text-with-change;
        }
      }

      .LeaderboardNoDataView {
        margin: auto;
        padding: 50px;
        width: 500px;
      }
    }
  }
}

.LeaderboardTopPanel {
  @include card-style;
  grid-row: 2;
  grid-column: 1 / span 12;
  margin: 8px 16px;
  display: flex;
  flex-direction: row;
  text-align: left;

  .Divider {
    width: 1px;
    border-right: 1px solid $off-white-1;
  }

  .RankPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 32px;
    width: 50%;
    padding: 16px;

    .Heading {
      font-weight: 700;
      display: flex;
      flex-direction: column;
      align-self: center;

      .Title {
        font-size: 10px;
        text-transform: uppercase;
      }

      .Location {
        font-size: 18px;
        padding-right: 5px;
      }
    }

    .Ranking {
      display: flex;
      flex-direction: row;

      .PreviousRanking {
        @include leaderboard-text-with-change;
        font-size: 12px;
        font-weight: 400;
        align-self: center;
        padding-right: 28px;
      }

      .AdditionalLabel {
        font-size: 12px;
        font-weight: 400;
        align-self: center;
        padding-right: 28px;
      }

      .LeaderboardRanking {
        @include leaderboard-ranking;
        @include leaderboard-ranking-with-change;

        box-shadow: none;
      }
    }
  }
}

.LeaderboardTopThree {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 48px 16px;
  margin: 32px;
  background: linear-gradient(223.45deg, #c1dbce 3.44%, #5da0a2 90%);
  mix-blend-mode: normal;
  border-radius: 4px;

  .LeaderboardRanking {
    @include leaderboard-ranking;
    margin-right: auto;
    margin-left: auto;
    border: 4px solid rgba(255, 255, 255, 0.7);
  }

  .TopThreeItem {
    width: calc(100% / 3);
    text-align: center;

    .Name {
      font-size: 18px;
      font-weight: 700;
      color: $white;
      margin-top: 26px;
    }
  }
}
