@import '../../Colours.module.scss';

.Tooltip {
  width: 200px;
  margin-left: 22px;
  margin-right: 22px;
  padding-top: 19px;
  padding-bottom: 10px;
  white-space: normal;

  &.TooltipWide {
    width: 260px;
  }

  &.TooltipRefrigerants {
    width: 360px;

    .ItemsList {
      li {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr;

        .Measurement {
          text-align: right;
        }
      }

      &.Summary {
        text-align: right;
      }
    }

    .Heading {
      display: grid;
      grid-template-columns: 3fr 2fr 2fr;
    }
  }

  .Heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 10px;
    font-weight: bold;
    line-height: 18px;
    color: $grey-4;
    text-transform: uppercase;

    span:first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 20px;
    }
  }

  .ItemsList {
    font-size: 12px;
    list-style: none;
    padding-left: 0px;
    margin-block-end: 0px;
    border-top: 1px solid $off-white-1;
    padding: 8px 0 8px 0;
    margin-top: 0px;

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 6px;
      padding-bottom: 6px;

      &.Difference {
        border-bottom: 1px solid $off-white-1;
      }
    }

    .Measurement {
      font-family: Roboto Mono;
      white-space: nowrap;
    }

    &.Summary {
      padding-bottom: 0;
      margin-left: -22px;
      margin-right: -22px;
      padding-left: 22px;
      padding-right: 22px;

      .Measurement {
        font-weight: 700;

        &.OnTrack {
          color: $green;
        }

        &.NotOnTrack {
          color: $red;
        }
      }
    }
  }

  .Deliveries {
    li {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  .LegendContainer {
    display: flex;
    flex-direction: row;

    .TooltipNotificationIcon {
      display: inline-block;
      width: 16px;
      height: 16px;
      padding-right: 6px;
      vertical-align: text-bottom;
    }
  }

  .ColorIndicator {
    margin-right: 6px;
    vertical-align: text-bottom;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  .Unit {
    color: $grey-4;
    padding-right: 6px;
  }

  .Content {
    min-height: 32px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;

    .Measurement {
      font-family: Roboto Mono;
    }
  }
}

.SidebarTooltip {
  width: 140px;
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.TooltipWrapper {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.1);
  width: auto;
  height: auto;
}

.CurrentPeriodTooltipIndicator {
  fill: $salmon;
}

.PreviousPeriodTooltipIndicator {
  display: flex;
  align-items: center;
  border-right: 2px solid $black;
  height: 18px;

  svg {
    fill: $light-pink;
  }
}

.YTDAverageTooltipIndicator {
  border-left: 2px solid $orange-3;
  margin-left: 7px;
}
