.IntroSlide {
  display: flex;
  height: 100vh;

  .IntroSlideLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50vw;
    background: radial-gradient(69.49% 200.93% at 27.26% 52.38%, #308082 0%, rgba(94, 160, 162, 0) 100%),
      linear-gradient(256.39deg, #89c4cc 7.91%, #5da0a2 77.87%);
    color: white;
    box-sizing: border-box;
    padding: 0 10vw;

    h1 {
      color: white;
      font-weight: bold;
      font-size: 48px;
      line-height: 68px;
      margin-bottom: 90px;
    }

    p {
      font-size: 30px;
      line-height: 50px;
    }
  }

  .IntroSlideRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50vw;
    padding: 0 5vw;
    box-sizing: border-box;

    h2 {
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 24px;
    }

    p {
      font-size: 24px;
      line-height: 30px;
    }

    ul {
      margin-bottom: 64px;
    }

    li {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }
}
