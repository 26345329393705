@import '../Colours.module.scss';

.CheckboxContainer {
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;

  .CheckBox {
    border: 1px solid $grey-4;
    border-radius: 2px;
    width: 16px;
    height: 16px;

    &.Checked {
      border: 1px solid $yellow;
      background: $yellow url('../images/smallcheck.svg') no-repeat center;
      background-size: 11px 8px;
    }
  }
}
