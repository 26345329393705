@import '../Colours.module.scss';

$separator: 1px solid $off-white-1;
$padding: 32px;

.NavigationIcon {
  background-color: $off-white-3;
  height: 100%;
  border-right: $separator;
  cursor: pointer;

  svg {
    margin: 20px 30px;
  }
}

.NavItemText {
  height: 20px;
  line-height: 20px;
}

.BetaLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 1px solid $red-ca;
  color: $red-ca;
  border-radius: 18px;
  font-size: 8px;
  font-weight: 500;

  padding-left: 4px;
  padding-right: 4px;
  margin-right: 4px;

  height: 18px;
}

.NavigationModal {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;

  a:hover {
    color: $grey-4;
  }

  .NavigationHeader {
    /* These match SkapaModal's header's values */
    height: 5.5rem;
    line-height: 5.5rem;

    color: white;
    padding-left: $padding;

    &.retail {
      background-color: $blue-1;
    }

    &.centres {
      background-color: $off-white;
    }

    &.ingka {
      background-color: $black;
    }

    .Selected {
      font-weight: bold;
    }

    .Change {
      opacity: 0.8;
      font-size: 14px;
      padding-left: 8px;
      cursor: pointer;
    }

    .FunctionAreaMenu {
      position: relative;
      background-color: white;
      color: black;
      top: -25px;
      left: 253px;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
      width: 100px;
      border-radius: 8px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);

      .FunctionAreaOption {
        cursor: pointer;

        &.SelectedOption {
          background-color: $grey-1;
        }

        &:hover {
          background-color: $grey-1;
        }

        &:first-child {
          border-radius: 8px 8px 0 0;
        }

        &:last-child {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }

  .TopLevelLink {
    display: block;
    height: 50px;
    line-height: 50px;
    padding-left: $padding;
    border-bottom: $separator;
    font-size: 13px;
    font-weight: bold;
  }

  .KpiPages {
    padding-left: $padding;
    border-bottom: $separator;
    padding-bottom: 16px;

    .Label {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 16px;
    }

    a {
      font-size: 13px;
      line-height: 16px;
    }
  }

  .NavigationFooter {
    margin-top: auto;
    padding: $padding $padding $padding 0;
    font-size: 12px;

    a {
      margin-left: 28px;

      &:first-child {
        margin-left: $padding;
      }
    }

    svg {
      width: 20px;
      vertical-align: middle;
      margin-right: 6px;
    }
  }
}

.SkapaModalHeader {
  pointer-events: none;

  button {
    pointer-events: all;
  }
}
