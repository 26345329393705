@import '../../node_modules/@ingka/carousel/_mixins.scss';

.InsightCarousel {
  margin: 0 32px 32px 32px;

  .InsightCarouselContent {
    padding: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;

    .InsightSlide {
      max-width: calc(100% - $space-125);
      @include slideSize($gap: $space-125, $fitItems: 1);

      .ModalInsightGraphContainer {
        width: 30%;
      }
    }
  }
}
