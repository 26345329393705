.SimpleLegend {
  font-size: 12px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;

  span:first-child .SimpleLegendDot {
    margin-left: 0;
  }

  .SimpleLegendContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .SimpleLegendDot {
    border-radius: 999px;
    display: flex;
    justify-content: center;
    height: 16px;
    width: 16px;
    margin-right: 12px;
    margin-left: 12px;
    vertical-align: middle;
  }

  .SimpleLegend:first-of-type {
    .SimpleLegendDot {
      margin-left: 0;
    }
  }
}
