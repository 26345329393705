@import '../Mixins.scss';
@import '../Colours.module.scss';

.NotificationList {
  .NewFeatureBadge {
    @include badge;

    background: $blue;
  }

  .NotificationsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    padding: 16px 16px;
    border-bottom: 1px solid $off-white-1;

    .NotificationsHeading {
      font-weight: 700;
    }

    .MarkAllAsRead {
      font-weight: 400;
      color: $grey-4;
      cursor: pointer;
    }
  }

  .NotificationItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 16px 0 0;

    &.Link {
      cursor: pointer;
    }

    .NotificationTexts {
      max-width: 85%;

      .NotificationTitle {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
      }

      .NotificationText {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-top: 10px;
      }

      .NotificationDate {
        font-weight: 700;
        font-size: 10px;
        line-height: 20px;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 24px;
      }
    }

    .NotificationIndicator {
      position: relative;
      margin-top: 6px;

      &.Unread::after {
        @include unread;
      }
    }
  }
}

.MarkAllButton {
  display: flex !important;
}
