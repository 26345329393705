@import '../node_modules/@ingka/svg-icon/_mixins.scss';
@import '../node_modules/@ingka/button/_mixins.scss';
@import '../node_modules/@ingka/modal/_mixins.scss';
@import '../node_modules/@ingka/banner/_mixins.scss';
@import '../node_modules/@ingka/inline-message/_mixins.scss';
@import '../node_modules/@ingka/focus/_mixins.scss';
@import '../node_modules/@ingka/skeleton/_mixins.scss';
@import '../node_modules/@ingka/toast/_mixins.scss';
@import '../node_modules/@ingka/tooltip/_mixins.scss';
@import '../node_modules/@ingka/accordion/_mixins.scss';
@import '../node_modules/@ingka/carousel/_mixins.scss';

@include svg-icon();
@include btn();
@include banner();
@include btn-focus();
@include modal('', 'sheets theatre');
@include skeleton();
@include toast();
@include tooltip();
@include accordion();
@include carousel();
@include inline-message();

.toast {
  top: 4rem;

  // workaround for a Skapa bug, part of toast remains visible in screen even when hidden
  &[aria-hidden='true'] {
    right: -26rem;
  }
}

.sheets > .sheets__content-wrapper,
.prompt > .prompt__content-wrapper > .prompt__content {
  // Do not show the default browser outline when modal receives focus when opened
  outline: 0;
}

.carousel > .carousel__wrapper > .carousel__button--right {
  // Fix Carousel bug where next slide button is misaligned compared to the previous slide button
  right: -0.25em;
}
