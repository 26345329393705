@import '../Mixins.scss';

:global {
  @mixin select($name) {
    .#{$name} {
      .#{$name}__control {
        background-color: white;
        color: black;
        box-shadow: none;
        border: 0;
        padding-left: 8px;
        width: 100%;

        @media only screen and (max-width: 700px) {
          min-height: 0px;
          height: 28px;
        }

        &:hover {
          border-color: none;
          box-shadow: none;
        }
      }

      .#{$name}__input {
        color: black;
        font-weight: bold;
        margin-left: 36px;
      }

      .#{$name}__menu {
        background-color: white;
        color: black;
        margin-top: 4px;
        width: 100%;
        font-size: $font-size-menu-nav;
      }

      .#{$name}__option {
        background-color: white;
        color: black;
        padding: 8px 12px 8px 16px;

        &:hover {
          background-color: $off-white-2;
        }
      }

      .#{$name}__single-value {
        color: black;
        font-size: $font-size-menu-nav;
      }

      .#{$name}__indicator-clear {
        color: $white;
      }
    }
  }

  @include select('LocationSearch');
  @include select('FunctionAreaSelect');
}

.DropdownIndicator {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 8px;
  background-image: url('../images/CircleArrow/Grey.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  transform: rotate(90deg);

  &.Open {
    transform: rotate(-90deg);
  }
}

.CountryIcon {
  background-image: url(../images/Icons/Globe.svg);
  background-repeat: no-repeat;
  margin-top: 4px;
  margin-right: 10px;
  margin-left: 6px;
  width: 20px;
  height: 20px;
}

.EmptyIcon {
  margin-top: 4px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 6px;
}

.ClusterOption {
  font-weight: bold;
  padding-left: 5px;
}
