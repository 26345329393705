.stress-level-conteiner {
  width: 200px;
  margin-bottom: 20px;

  > div {
    padding-left: 10px;
    color: #fff;
  }

  .level-low {
    background: #00853e;
  }

  .level-low-medium {
    background: #5cc27c;
  }

  .level-medium-high {
    background: #ffdb00;
  }

  .level-high {
    background: #ec773e;
  }

  .level-extremely-high {
    background: #cc0008;
  }
}

.water-formula {
  display: flex;
  margin-bottom: 20px;

  &__first {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }

  &__denominator {
    border-top: solid 1px #000;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
}
