@import '../Mixins.scss';
@import '../Colours.module.scss';

.TopSellingArticles {
  .LoadingSkeleton {
    height: 400px;
  }

  .ChartContainer {
    margin-top: 16px;

    .YAxisText,
    .HorizontalBarChartValue {
      font-size: 11px;
    }

    foreignObject {
      overflow: visible;
    }

    .ChartWithYTitle .Chart .YAxisText {
      line-height: unset;
      height: 100%;
      overflow: visible;

      .PPPArticleNameAndNo {
        padding-top: 2px;
        font-size: 14px;
        font-weight: 700;

        .PPPArticleNo {
          background-color: $grey-1;
          font-size: 12px;
          padding: 2px 4px;
          border-radius: 4px;
        }
      }
    }

    .xAxisTitle {
      font-size: 10px;
    }

    .Tooltip {
      .Heading {
        span {
          align-self: flex-end;

          &:first-child {
            overflow: visible;
            white-space: normal;
          }
        }
      }
    }
  }

  .HFBLegend {
    @include hfblegend;
    font-size: 12px;
    display: flex;
  }
}
