@import '../../Mixins.scss';
@import '../../Colours.module.scss';

.HfbSlide {
  .InStoreSlideRight {
    padding-top: 90px;

    .HfbSlideHeader {
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    .HFBLegend {
      @include hfblegend;
      display: flex;
      line-height: 32px;
      position: absolute;
      bottom: 65px;
      margin-left: 50px;
      font-size: 22px;

      .CurrentPeriodIndicator {
        height: 32px;
        width: 32px;
        margin-right: 16px;
        fill: $salmon;

        circle {
          cx: 16px;
          cy: 16px;
          r: 16px;
        }
      }

      .LastPeriodIndicator {
        border-left: 2px solid $salmon-1;
        margin-right: 16px;
        margin-left: 64px;
      }
    }

    .ChartContainer {
      .ChartWithYTitle {
        .Chart {
          .YAxisText {
            font-size: 22px;
            line-height: 37px;
          }
          .HorizontalBarChartValue {
            font-size: 16px;
            transform: translateX(12px);
          }

          line {
            stroke: $off-white-5;
          }
        }
      }
    }
  }
}
