@import '../Colours.module.scss';

.SelfServicePage {
  padding: 32px;
}

h1 {
  text-align: center;
}

.DagList {
  margin-top: 32px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;

  align-items: center;

  padding: 0;
  list-style-type: none;
}

.DagCard {
  background: $white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex-shrink: 0;
  width: 750px;

  summary {
    font-size: 14px;
    font-weight: 600;

    padding: 16px;
    border-bottom: 1px solid $grey-2;

    display: flex;
    justify-content: space-between;

    & span:last-child {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: $red;
    }
  }

  .DagCardContents {
    padding: 16px;
    display: flex;
    gap: 16px;

    .DatesContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-right: 1px solid $grey-2;
      padding-right: 16px;
      width: 200px;

      .Header {
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .Status {
        margin-top: -12px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;

        .Failed {
          color: $red;
        }
        .Queued {
          color: $light-blue-1;
        }
        .Running {
          color: $orange;
        }
        .Ok {
          color: $green;
        }
        .Unknown {
          color: $pink;
        }
        .Loading {
          color: $off-white-1;
        }
      }

      .DateGroup {
        display: flex;
        flex-direction: column;
        gap: 0px;

        .DateLabel {
          font-size: 14px;
          font-weight: 600;
        }
        .Date {
        }
      }
    }

    .ConfigContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      width: 300px;

      .Header {
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .ConfigList {
        padding-left: 0px;
        list-style-type: none;
        flex-grow: 1;
      }

      .TriggerButtonsContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;

        button {
          padding: 8px 0;
        }
      }
    }
  }
}

.DocumentationLinkContainer {
  text-align: center;
}

.DocumentationLink {
  text-align: center;
  font-weight: 600;
  color: $light-blue-1;
  text-decoration: underline;

  &:active {
    color: $teal;
  }

  &:visited {
    color: $purple;
  }
}
