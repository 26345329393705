@import '../Mixins.scss';
@import '../Colours.module.scss';

.SnapshotPage {
  $topMargin: 24px;
  $margin: 16px;

  min-height: unset;

  .PageContainer {
    max-width: 1920px;
    margin: auto;
  }

  .SnapshotBanner {
    grid-row: 2;
    grid-column: 1 / span 12;
  }

  .InsightHeader {
    @include snapshot-section-header;
    grid-row: 5;
    grid-column: 1 / span 12;
  }

  .InsightCards {
    grid-column: 1 / span 12;
    grid-row: 6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 24px;
    margin: 8px 32px;
    min-height: 308px;

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
      row-gap: 20px;
    }

    .InsightCard {
      @include card-style;
      margin: 0;
      width: 50%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      h3 {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        padding: 12px 16px 8px 16px;
        border-bottom: 1px solid $off-white-1;
        margin-bottom: 0;
      }

      .InsightCardContent {
        display: flex;
        height: 100%;
        flex-direction: row;

        .InsightCardHalf:first-of-type {
          border-right: 1px solid #e7e7e7;
        }

        .InsightCardHalf {
          display: flex;
          flex-direction: column;
          width: 50%;
          justify-content: space-between;
          border-right: 1px solid $off-white-1;
          padding: 16px 16px 0 16px;

          .InsightTexts {
            display: flex;
            flex-direction: column;
            padding: 16px 16px 0 16px;

            p {
              font-size: 18px;
              font-weight: 700;
              margin-top: 0px;
            }
          }

          .LearnMore {
            background-color: white;
            font-weight: 700;
            font-size: 12px;
            border-right: 1px solid $off-white-1;
            padding-left: 16px;
            padding-bottom: 0.5em;
          }

          .InsightGraphLegend {
            padding: 0.5em 0;
          }

          .InsightGraph {
            width: 50%;
            margin: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }

    .LoadingSkeleton {
      width: 50%;
    }

    .InsightMissing {
      @include card-style;
      width: 50%;
      height: 100%;
      box-sizing: border-box;
      padding: 64px;
    }
  }
}

.SimpleLegend {
  display: flex;
  justify-content: center;
}
