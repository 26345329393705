$white: #ffffff;
$off-white: #9a948c;
$off-white-1: #e7e7e7;
$off-white-2: #f7f7f7;
$off-white-3: #f5f5f5;
$off-white-4: #ebebeb;
$off-white-5: #dddddd;
$off-white-6: #fafafa;
$off-white-7: #e9e0e0;
$grey-1: #f1f1f1;
$grey-2: #dfdfdf;
$grey-3: #929292;
$grey-4: #767676;
$grey-5: #757575;
$grey-6: #666666;
$grey-7: #35363c;
$black: #000000;
$black-1: #111111;
$black-2: #121213;
$dark-blue: #00324a;
$dark-blue-1: #003f5c;
$blue: #0058a3;
$blue-1: #0c518b;
$blue-2: #004f93;
$blue-3: #0a57b4;
$blue-4: #3d548c;
$blue-5: #006699;
$blue-6: #0e6ece;
$blue-7: #4a5899;
$blue-8: #03a9f4;
$blue-9: #3e538d;
$blue-10: #003e72;
$light-blue: #2d9cdb;
$light-blue-1: #1c96da;
$light-blue-2: #a5b4d9;
$light-blue-3: #529ee0;
$light-blue-4: #8793be;
$light-blue-5: #a9cfef;
$light-blue-6: #c8cedd;
$light-blue-7: #64a6bd;
$light-blue-9: #bbd7f3;
$light-blue-10: #b2bbd1;
$light-blue-8: #fee7ae;
$light-blue-11: #539ee1;
$light-blue-12: #03a9f4;
$light-blue-13: #b3e5fc;
$light-blue-14: #a5b4d9;
$teal: #0a6c9d;
$light-yellow: #fee8ae;
$light-yellow-1: #fee7ae;
$yellow: #ffdd45;
$yellow-1: #fdc22d;
$yellow-2: #ffcb45;
$yellow-3: #f0e442;
$orange: #f59b00;
$orange-1: #ffc87a;
$orange-2: #ff764a;
$orange-3: #ffa600;
$orange-4: #ffdb98;
$orange-5: #e67067;
$light-green: #7fc97f;
$light-green-1: #a9c9c0;
$light-green-2: #83c8b6;
$green: #1aaf40;
$green-1: #08916d;
$green-2: #0cd9a3;
$green-3: #0a916d;
$green-4: #9bd4c4;
$red: #dd2a1a;
$red-ca: #ca5008;
$light-pink: #ffd7cc;
$light-pink-1: #f3bcb9;
$light-pink-2: #d9a6c2;
$light-pink-3: #f0dbe7;
$light-pink-4: #fcc2ba;
$pink: #bd8b9c;
$pink-1: #ef5675;
$hot-pink: #bc5090;
$hot-pink-1: #b03e67;
$salmon: #ffa58c;
$salmon-1: #e66f68;
$salmon-2: #ff764a;
$salmon-3: #ffccbc;
$light-purple: #cbaeea;
$light-purple-1: #b6a1c4;
$purple: #6c58ab;
$purple-1: #7530bd;
$purple-2: #7a5195;
$dark-purple: #441c6e;
$climate-footprint-gradient: linear-gradient(103.99deg, #56a062 14.7%, #cbe54e 100.66%);
$healthy-living-gradient: linear-gradient(103.16deg, #006699 6.6%, #529ee0 105.47%);
$social-impact-gradient: linear-gradient(106.51deg, #d4574f 3.82%, #faac6a 109.47%);
$sustainability-mentions-gradient: linear-gradient(106.51deg, #96546b 3.82%, #bd8b9c 109.47%);
$ppp-sales-gradient: linear-gradient(102.66deg, #6c58ab 7.03%, #ab8bc0 97.66%);
$zero-waste-gradient: linear-gradient(103.99deg, #046c73 14.7%, #83c8b6 100.66%);
$healthy-living-snapshot: linear-gradient(95.08deg, #0a6c9d 14.26%, #1c96da 83.94%);
$zero-waste-snapshot: linear-gradient(103.99deg, #046c73 14.7%, #83c8b6 100.66%);
$climate-footprint-snapshot: linear-gradient(103.99deg, #56a062 14.7%, #cbe54e 100.66%);
$social-impact-snapshot: linear-gradient(106.51deg, #d4574f 3.82%, #faac6a 109.47%);
$ppp-sales-snapshot: linear-gradient(102.66deg, #6c58ab 7.03%, #ab8bc0 97.66%);
$sustainability-mentions-snapshot: linear-gradient(106.51deg, #96546b 3.82%, #bd8b9c 109.47%);
$circular-revenu-gradient: linear-gradient(103.99deg, #6c58ab 14.7%, #ab8bc0 100.66%);

:export {
  white: $white;
  offWhite: $off-white;
  offWhite1: $off-white-1;
  offWhite2: $off-white-2;
  offWhite3: $off-white-3;
  offWhite4: $off-white-4;
  offWhite5: $off-white-5;
  offWhite6: $off-white-6;
  offWhite7: $off-white-7;
  grey1: $grey-1;
  grey2: $grey-2;
  grey3: $grey-3;
  grey4: $grey-4;
  grey5: $grey-5;
  grey6: $grey-6;
  grey7: $grey-7;
  black: $black;
  black1: $black-1;
  black2: $black-2;
  darkBlue: $dark-blue;
  darkBlue1: $dark-blue-1;
  blue: $blue;
  blue1: $blue-1;
  blue2: $blue-2;
  blue3: $blue-3;
  blue4: $blue-4;
  blue5: $blue-5;
  blue6: $blue-6;
  blue7: $blue-7;
  blue8: $blue-8;
  blue9: $blue-9;
  blue10: $blue-10;
  lightBlue: $light-blue;
  lightBlue1: $light-blue-1;
  lightBlue2: $light-blue-2;
  lightBlue3: $light-blue-3;
  lightBlue4: $light-blue-4;
  lightBlue5: $light-blue-5;
  lightBlue6: $light-blue-6;
  lightBlue7: $light-blue-7;
  lightBlue8: $light-blue-8;
  lightBlue9: $light-blue-9;
  lightBlue10: $light-blue-10;
  lightBlue11: $light-blue-11;
  lightBlue12: $light-blue-12;
  lightBlue13: $light-blue-13;
  lightBlue14: $light-blue-14;
  teal: $teal;
  lightYellow: $light-yellow;
  lightYellow1: $light-yellow-1;
  yellow: $yellow;
  yellow1: $yellow-1;
  yellow2: $yellow-2;
  yellow3: $yellow-3;
  orange: $orange;
  orange1: $orange-1;
  orange2: $orange-2;
  orange3: $orange-3;
  orange4: $orange-4;
  orange5: $orange-5;
  lightGreen: $light-green;
  lightGreen1: $light-green-1;
  lightGreen2: $light-green-2;
  green: $green;
  green1: $green-1;
  green2: $green-2;
  green3: $green-3;
  green4: $green-4;
  red: $red;
  redCA: $red-ca;
  lightPink: $light-pink;
  lightPink1: $light-pink-1;
  lightPink2: $light-pink-2;
  lightPink3: $light-pink-3;
  lightPink4: $light-pink-4;
  pink: $pink;
  pink1: $pink-1;
  hotPink: $hot-pink;
  hotPink1: $hot-pink-1;
  salmon: $salmon;
  salmon1: $salmon-1;
  salmon2: $salmon-2;
  salmon3: $salmon-3;
  purple: $purple;
  purple1: $purple-1;
  purple2: $purple-2;
  lightPurple: $light-purple;
  lightPurple1: $light-purple-1;
  purlple1: $purple-1;
  purple2: $purple-2;
  darkPurple: $dark-purple;
}
