.PromptContainer {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.FixedHeightContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
  padding: 32px 42px;

  p {
    margin: 0;
  }
  b {
    font-weight: bold;
  }
}

.IllustrationImage {
  display: block;
  width: 110px;
  margin: 20px;
}

.Button {
  width: 240px;
}
