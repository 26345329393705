@import '../../Mixins.scss';
@import '../../Colours.module.scss';

.InStoreSlide {
  display: flex;

  .InStoreSlideLeft {
    box-sizing: border-box;
    border-right: 4px solid $off-white-1;
    width: 33vw;

    .InStoreSlideLeftTop {
      border-bottom: 4px solid $off-white-1;
      box-sizing: border-box;
      height: 60vh;
      padding: 90px 100px;
      overflow: hidden;

      &.Full {
        border-bottom: none;
        height: 100vh;
      }

      .InStoreSlideLocation {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.2;
        margin-top: 0;
      }

      .InStoreSlideTitle {
        font-size: 44px;
        line-height: 1.2;
        text-align: left;
      }

      .InStoreSlideDescription {
        font-size: 26px;
        line-height: 1.5;
      }
    }

    .InStoreSlideLeftBottom {
      box-sizing: border-box;
      height: 40vh;
      padding: 80px 100px;
      overflow: hidden;

      .InStoreSlideSeries {
        font-weight: bold;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: 0.2px;
        margin: 0;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
      }

      .InStoreSlideSeriesValue {
        font-size: 96px;
        font-weight: bold;
        margin-top: 0;
        white-space: nowrap;
      }

      .InStoreSlideSeriesUnit {
        font-size: 32px;
        line-height: 1.6;
        margin-left: 10px;
      }

      .InStoreSlideSeriesBadge {
        margin-top: 40px;
        white-space: nowrap;

        span {
          @include badge;
          background-color: black;
          font-size: 24px;
          line-height: 1.2;
          padding: 12px 16px;

          &.OnTrack {
            background-color: $green;
          }

          &.NotOnTrack {
            background-color: $red;
          }
        }
      }
    }

    .InStoreSlideDataSource {
      color: $grey-4;
      font-size: 20px;
      position: absolute;
      bottom: 60px;
      left: 100px;
    }
  }

  .InStoreSlideRight {
    box-sizing: border-box;
    padding: 165px 80px 110px 90px;
    width: 67vw;
    overflow: hidden;

    .graphContainer {
      height: 100%;
      width: 100%;

      .headingAndMessage {
        display: none;
      }

      .body {
        display: none;
      }

      &.LessXAxisTicks {
        g:nth-child(odd) {
          .XAxisTick,
          .XAxisValue {
            display: none;
          }
        }
      }
    }

    .ChartWithYTitle {
      .NegativeChangeOverlay {
        display: none;
      }

      .HorizontalTickValue {
        font-size: 12px;
        transform: translateY(5px);
      }

      .YAxisTitle {
        align-items: unset;
        margin-top: -75px;
        width: 0;

        span {
          transform: unset;
          font-size: 20px;
          line-height: 1.4;
          white-space: break-spaces;
        }
      }

      .YAxisValue {
        font-size: 22px;
      }

      .xAxisTitle {
        font-size: 12px;
      }

      .XAxisValue {
        font-size: 18px;
        transform: translateY(10px);
      }

      .XAxisTick {
        display: none;
      }

      .HighlightedSerie {
        stroke-width: 5px !important;
        stroke-opacity: 1 !important;
      }

      .RegularSerie {
        stroke-width: 3px !important;
        stroke-opacity: 0.2 !important;
      }

      path {
        stroke-width: 4px;
      }
    }

    .ChartContainer {
      padding-bottom: 50px;
      overflow: visible;

      & > .Legend {
        transform: translateY(50px);
      }
    }

    .Legend {
      margin-left: 50px !important;

      .LegendItems {
        span {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        .Item {
          .LegendIcon {
            width: 32px;
            height: 32px;
          }

          .ItemText {
            font-size: 22px;
            line-height: 32px;
          }
        }
      }

      & > .SkapaTooltip {
        display: none;
      }
    }
  }

  & > .LoadingSkeleton {
    height: 100vh;
  }
}
