.ChartContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 5px 16px 16px 2px;
  overflow-y: hidden;

  .ChartWithYTitle {
    height: 100%;
    display: flex;
    flex-direction: column;

    .YAxisTitle {
      font-size: 10px;
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      margin-bottom: 20px;
    }

    .SafariWorkaroundContainer {
      /* This is to work around Safari miscalculating SVG height inside a flexbox */

      flex-grow: 1;
      position: relative;

      @media only screen and (max-width: 1024px) {
        height: 300px;
      }

      .Chart {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media only screen and (max-width: 1024px) {
          height: 300px;
        }
      }
    }

    .Chart {
      overflow: visible;
      pointer-events: visiblePainted;
      text-transform: uppercase;

      .YAxisText {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: none;
        line-height: 24px;
      }

      .XAxisLabel {
        text-transform: none;
      }

      path {
        transition: stroke-width 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }

  .Legend {
    padding-top: 16px;
  }
}

.tooltip-legendItem-wrapper {
  width: fit-content;
}
