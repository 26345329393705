@import './Colours.module.scss';

$border-gray: 2px solid $grey-1;
$border-dark-gray: 2px solid rgba(118, 118, 118, 0.5);

$navigation-width: 97px;

$font-size-menu: 12px;
$font-size-menu-nav: 14px;

@mixin twelve-column-grid {
  display: grid;
  grid-template-columns: repeat(12, [col] 1fr) [end];
}

@mixin for-smaller-screen {
  @media (max-width: 1366px) {
    @content;
  }
}

@mixin responsive-padding {
  padding: 32px;

  @include for-smaller-screen {
    padding: 16px;
  }
}

@mixin card-style {
  margin: 8px;
  background: $white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

@mixin clickable-card {
  transition: transform 0.1s;

  &:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);
    transform: scale(1.03);
  }
}

@mixin central-graph-grid-layout {
  @include twelve-column-grid;
  min-height: 100vh;
  grid-template-rows: repeat(3, min-content);

  .Insights {
    @include card-style;
    margin-left: 16px;
    height: fit-content;
    grid-row: 2 / span 3;
    grid-column: col 1 / span 3;
  }

  .SideGraphs {
    margin: 8px 16px;
    border-radius: 4px;

    grid-row: 3;
    grid-column: col 1 / span 9;
    display: flex;

    & > * {
      @include responsive-padding;
      flex: 1 1 0px;
    }
  }
}

@mixin learnmore-button {
  background: $white;
  border: 1px solid $grey-2;
  box-sizing: border-box;
  border-radius: 999px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  height: 40px;
  padding: 0 24px;
  right: 20px;
  top: 16px;

  &:hover {
    border: 1px solid $grey-3;
  }

  &:focus {
    outline: none;
  }
}

@mixin graph-description-row {
  .DescriptionRow {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .Description {
      font-size: 12px;
      line-height: 20px;
      font-weight: normal;
      color: $black;
    }

    .ArticlesLegend {
      font-size: 12px;
      line-height: 20px;

      span {
        margin-left: 8px;
      }

      .CurrentWeekIndicator {
        fill: $salmon;
      }

      .LastWeekIndicator {
        border-left: 2px solid black;
        margin-left: 20px;
      }
    }
  }
}

@mixin blue-button {
  background-color: $blue;
  border: none;
  border-radius: 999px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 56px;
  margin-right: 80px;
  margin-top: 34px;
  pointer-events: all;
  text-transform: uppercase;
  width: 200px;
  padding: 0 24px;

  &:focus {
    outline: 0;
  }
}

@mixin click-catcher {
  background-color: transparent;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
}

@mixin hfblegend {
  align-self: center;
  margin-right: 8px;

  .CurrentPeriodIndicator {
    fill: $salmon;
    margin-right: 6px;
    align-self: center;
  }

  .LastPeriodIndicator {
    border-left: 2px solid black;
    margin-left: 20px;
    margin-right: 6px;
  }
}

@mixin unread {
  content: '';
  background: $light-blue;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  margin-left: -10px;
  margin-top: -1px;
}

@mixin badge {
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  margin: 0;
  padding: 4px 6px;
}

@mixin link-button {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  padding: 0px 16px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  height: 40px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

@mixin snapshot-section-header {
  margin-left: 32px;
  margin-top: 24px;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.3px;
}
