@import '../../Colours.module.scss';

.Legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .LegendItems {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    .dimmed {
      opacity: 0.5;
    }

    .Item {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;

      &:hover {
        cursor: pointer;

        .ItemText {
          transition: opacity 0.3s ease-in-out;
          opacity: 0.6;
        }
      }

      .LegendIcon {
        margin-right: 8px;
        flex-shrink: 0;
        transition: fill-opacity 0.3s ease-in-out;
        border-radius: 999px;
        box-sizing: border-box;

        &.Faded {
          fill-opacity: 0.2;
        }
      }

      .ItemText {
        display: inline-block;
        flex-shrink: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.Inactive {
          opacity: 0.6;
        }

        &.ExtraInactive {
          text-decoration: line-through;
        }
      }
    }

    .tooltip__custom-trigger-wrapper {
      max-width: 100%;
    }
  }

  .DeselectAll {
    background: $white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin: 0 16px;
    cursor: pointer;

    &:hover {
      background-color: rgba(241, 241, 241, 0.5);
    }

    .Content {
      position: relative;
      top: 8px;
      left: 8px;
      font-size: 24px;
      background-color: $yellow;
      height: 24px;
      width: 24px;
      border-radius: 100%;
      text-align: center;
      line-height: 20px;
      user-select: none;
    }
  }

  .AdditionalComponent {
    grid-column: -2 / span 2;
    justify-self: end;
    display: flex;
    align-self: flex-start;

    .UnitSelector {
      display: flex;
      align-self: flex-end;

      .UnitSelectorContainer {
        margin: 0;
      }
    }
  }
}
