@import '../Colours.module.scss';

.TabBar {
  display: flex;
  border-bottom: 1px solid $grey-2;

  .Tab {
    background: none;
    border: 0;
    color: $grey-4;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.42857;
    margin: 0;
    padding: 1rem 2px;
    text-align: left;
    position: relative;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &.ActiveTab {
      color: $black-1;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        border-radius: 1.5px;
        border-bottom: 0.1875rem solid $blue;
      }
    }

    & + .Tab {
      margin-left: 1rem;
    }
  }

  .Spacer {
    flex-grow: 1;
  }
}
