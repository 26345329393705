.SunburstFigureContainer {
  height: auto;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  padding: 26px 32px 24px 32px;

  > .Legends {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    font-size: 12px;

    > .LegendContainer {
      display: grid;
      grid-template-columns: 12px 1fr;
      column-gap: 8px;
      row-gap: 4px;
      align-items: center;
      flex-wrap: wrap;
      flex-shrink: 0;

      > .LegendBullet {
        flex-shrink: 0;
        display: inline-block;
        height: 1em;
        width: 1em;
        border-radius: 9999px;

        background-color: red;
      }

      > .Percentage {
        flex-basis: 100%;
        grid-column-start: 2;
        font-weight: 700;
      }
    }
  }
  > .SunburstContainer {
    margin: auto;
    width: 160px;
  }
}
