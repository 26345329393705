@import '../../Mixins.scss';
@import '../../Colours.module.scss';

.HorizontalComparisonBarChart {
  display: flex;
  margin: auto;

  .ChartLabel {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: space-around;
    margin-right: 12px;
  }
}

.VerticalComparisonBarChart {
  margin: auto;

  .ChartLabel {
    font-size: 12px;
  }
}

.PercentageBarChart {
  margin: auto;

  .ChartLabel {
    font-size: 12px;
    white-space: break-spaces;
  }

  .ChartBadge {
    @include badge;

    background: $black;
  }
}

.SimpleVerticalBarChart {
  margin: auto;

  .ChartLabels {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;

    .ChartLabel {
      flex: 1 1 auto;
      max-width: 15.625%;

      span {
        margin-left: -100%;
        margin-right: -100%;
        text-align: center;
      }
    }
  }
}

.SideBySideComparisonBarChart {
  display: flex;
  flex-direction: column;

  .SideBySideLabel {
    margin-bottom: 4px;
  }

  .SideBySideGraph {
    display: flex;
    height: 40px;

    .LabelLeft,
    .LabelRight {
      position: absolute;
      line-height: 40px;
    }

    .LabelLeft {
      left: -50px;
    }

    .LabelRight {
      right: -50px;
    }
  }
}

.OnTrack {
  fill: $green;
}

.NotOnTrack {
  fill: $red;
}
