@import '../Colours.module.scss';

@mixin leaderboard-ranking {
  flex-shrink: 0;
  width: 54px;
  height: 54px;
  margin-right: 28px;
  line-height: 54px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  border-radius: 100%;
  background: $white;
  background-clip: padding-box;
  color: $black;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  align-self: center;
}

@mixin leaderboard-ranking-with-change {
  border-width: 4px;
  border-style: solid;
  &.ChangePos {
    border-color: rgba(26, 175, 64, 0.3);
    color: $green;
  }

  &.ChangeNeg {
    border-color: rgba(221, 42, 26, 0.3);
    color: $red;
  }
}

@mixin leaderboard-text-with-change {
  &.ChangePos {
    color: $green;
  }
  &.ChangeNeg {
    color: $red;
  }
}
