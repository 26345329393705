@import '../Mixins.scss';
@import '../Colours.module.scss';

@mixin card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: $white;
}

.ConfigLoading {
  padding-top: 16px;
  height: 500px;
}

.ResultsLoading {
  height: 500px;
}

.PageContainer {
  display: flex;
  flex-direction: column;
}

.Stripe {
  @include card();

  margin: 24px 32px;
  padding: 0 32px;
  height: 96px;

  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;

  .Title {
    font-size: 16px;
    font-weight: 600;
    flex-shrink: 0;
  }
  .Description {
    font-size: 14px;
    flex-grow: 1;
  }
  .LearnMoreButton {
    flex-shrink: 0;
  }
}

.PageContentContainer {
  display: flex;
  align-items: flex-start;
  padding: 0 32px 32px 32px;
  gap: 16px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.ConfigGroupsContainer {
  @include card();

  width: calc(100% * 5 / 12);
  padding: 0 32px 32px 32px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1024px) {
    width: calc(100% - 64px);
  }

  > .Accordion {
    border-bottom: 1px solid $grey-2;

    :global {
      .accordion__heading {
        border-top: none;
      }
      .accordion__item--active > .accordion__content {
        padding: 0 0 16px 0;
      }
    }
  }

  > .ConfiguratorGroup {
    display: flex;
    flex-direction: column;
    gap: 32px;

    padding: 16px 0;
    border-bottom: 1px solid $grey-2;
    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }

    > .Heading {
      padding-top: 16px;
      font-weight: 600;
    }
    > .Configurator {
      font-size: 12px;

      > .Heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        :global {
          .Toggle {
            padding-top: 0;
            flex-shrink: 0;
            height: 1em;
          }
          .Toggler {
            width: 42px;
            height: 16px;
            vertical-align: top;
          }
          .Toggler::before {
            top: 2px;
            width: 12px;
            height: 12px;
          }
        }
      }

      > .Content {
        margin-top: 8px;
        display: flex;
        gap: 4px;

        > .SliderContainer {
          flex-grow: 1;
          padding-left: 9px;
        }
        > .Ratio {
          min-width: 4em;
          text-align: end;
        }
        > .AmountsContainer {
          min-width: 12em;

          display: grid;

          > .Amount {
            display: flex;
            gap: 0.5em;

            > .MainHighlight,
            > .Highlight {
              flex-grow: 1;
              text-align: end;
            }
            > .MainHighlight {
              font-weight: 700;
            }
            > .Unit {
              width: 3.5em;
            }
          }
        }
      }
    }
  }
}

.ResultsContainer {
  @include card;

  width: calc(100% * 7 / 12); // 7 : 12
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media only screen and (max-width: 1024px) {
    width: calc(100% - 64px);
  }

  > .Totals {
    display: flex;
    padding: 0 32px;
    justify-content: space-between;

    > .Item {
      font-weight: 700;
      text-align: center;

      > .Title {
        font-size: 10px;
      }
      > .Value {
        font-size: 18px;
      }
    }
  }

  > .GraphContainer {
    height: 420px;
  }
}
