@import '../../Mixins.scss';
@import '../../Colours.module.scss';

@mixin card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: $white;
}

$description-height: 88px;

@mixin ytd {
  color: $red;

  &.OnTrack {
    color: $green;
  }

  &.Black {
    color: $black;
  }
}

.KPIPage {
  display: flex;
  flex-direction: column;

  .CardHeading {
    font-size: 14px;
    font-weight: 600;

    .CardHeadingColumn {
      display: flex;
      align-items: center;
    }

    .Icon {
      width: 18px;
      height: 18px;
      margin-left: 18px;
      margin-top: 4px;
      cursor: pointer;
      vertical-align: middle;
    }

    .Subtitle {
      .Unit {
        font-size: 11px;
        font-weight: 400;
        color: $grey-4;
      }
    }
  }

  .FirstItem {
    padding-left: 32px;
  }

  .Bold {
    font-weight: 600;
  }

  .TopBar {
    margin-bottom: 0;
  }

  .PageContent {
    transform: translateY(-74px);

    .NoDataView {
      width: 500px;
    }

    .KpiPageBanner {
      margin-bottom: 24px;
    }

    .BenchmarkingAndGoals {
      .Heading {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        padding-left: 12px;
        text-align: end;

        .IconWrapper {
          height: 24px;
          width: 24px;
          margin-left: 4px;
          vertical-align: middle;
          text-align: left;
          margin-top: 1px;
          // align-self: flex-start;
        }

        .Icon {
          fill: #767676;
          height: 16px;
          margin-left: 8px;

          &:hover {
            fill: #000000;
          }
        }

        .TimeRangeSelector.Heading {
          padding: 0;

          svg {
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
          }
        }
      }

      .Heading.FirstItem {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: center;
        margin-left: 18px;
      }

      .YTD {
        @include ytd();
      }

      .LoadingSkeleton,
      .NoDataViewContainer {
        grid-column: 1 / span 8;
        padding: 16px;
      }

      .NoDataViewContainer {
        text-align: left;
        grid-row: 4;
      }

      .Benchmarking {
        display: grid;
        grid-template-rows: 74px 24px max-content max-content max-content max-content;
        height: 100%;
        padding: 0 0 16px 0;

        @media only screen and (max-width: 768px) {
          grid-template-rows: max-content 24px 46px 46px 46px 46px;
        }

        .Heading.FirstItem {
          justify-content: flex-start;
          align-items: self-start;
          margin-top: 0;
          margin-bottom: 0;
          padding-bottom: 0;

          .FirstItem {
            padding-left: 0;
            margin: 0;
          }

          .Heading-label {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media only screen and (min-width: 600px) {
              max-width: 115px;
            }

            @media only screen and (min-width: 968px) {
              max-width: 195px;
            }

            @media only screen and (min-width: 968px) {
              max-width: 235px;
            }

            @media only screen and (min-width: 1060px) {
              max-width: 115px;
            }

            @media only screen and (min-width: 1200px) {
              max-width: 195px;
            }

            @media only screen and (min-width: 1434px) {
              max-width: max-content;
            }
          }

          .Heading-FirstItem {
            width: 85%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }

        &.Values1 {
          grid-template-columns: 80% 1fr 32px;

          .Separator {
            grid-column: 1 / span 3;
          }
        }

        &.Values2 {
          grid-template-columns: 60% 1fr 1fr 32px;

          .Separator {
            grid-column: 1 / span 4;
          }
        }

        &.Values3 {
          grid-template-columns: 30% 1fr 1fr 1fr 32px;

          .Separator {
            grid-column: 1 / span 5;
          }
        }

        &.Values4 {
          grid-template-columns: 30% 1fr 1fr 1fr 1fr 32px;

          .Separator {
            grid-column: 1 / span 6;
          }
        }

        &.Values5 {
          grid-template-columns: 30% 1fr 1fr 1fr 1fr 1fr 32px;

          .Separator {
            grid-column: 1 / span 7;
          }
        }

        &.Values6 {
          grid-template-columns: 30% 1fr 1fr 1fr 1fr 1fr 1fr 32px;

          .Separator {
            grid-column: 1 / span 8;
          }
        }

        .FirstItem {
          display: flex;
          text-align: left;
          align-items: flex-start;
          margin-bottom: 12px;
        }

        .Right {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }

        .Separator {
          border-top: 1px solid $off-white-1;
        }

        .SeeMore {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          grid-column: 1 / -1;

          & .Link {
            font-size: 14px;
            padding-left: 8px;
            font-weight: bold;

            &:hover {
              color: $grey-4;
            }
          }
        }

        .Black {
          color: $black;
        }

        .OnTrack {
          color: $green;
        }
      }

      .KPIPerformance {
        display: grid;
        grid-template-columns: 1fr 1fr 32px;
        grid-template-rows: 74px 24px repeat(5, max-content);
        height: 100%;
        padding: 0 0 16px 0;

        @media only screen and (max-width: 1060px) {
          padding: 0 0 16px 0;

          .FirstItem,
          .Right {
            margin-bottom: 12px;
          }
        }

        .Heading {
          align-items: center;
        }

        .FirstItem {
          display: flex;
          align-items: flex-start;
          margin-bottom: 12px;
        }

        .skipLast {
          align-items: flex-start;
        }

        .Heading.FirstItem {
          margin-left: 16px;
          margin-bottom: 0;
        }

        .Heading.FirstItem,
        .Heading.Right {
          margin-left: 32px;
          padding: 16px 0;
        }

        .Bold {
          font-weight: 600;
        }

        .Separator {
          border-top: 1px solid $off-white-1;
          grid-column: 1 / span 3;
        }

        .OnTrack {
          color: $green;
        }

        .NotOnTrack {
          color: $red;
        }

        .Right {
          display: flex;
          justify-self: flex-end;
          align-items: flex-start;
          gap: 10px;

          .GoalUnit {
            font-size: 14px;
            color: $grey-4;
            align-self: flex-start;
            height: 20px;
            text-align: end;
            display: flex;
            align-items: flex-end;
          }

          .Regular {
            font-weight: normal;
          }

          &.LastRow {
            align-items: flex-start;
            padding-bottom: 16px;
          }
        }

        .SkapaTooltip svg {
          width: 14px;
        }
      }

      .ppp-sales__performance {
        grid-template-rows: 74px 24px max-content max-content max-content max-content max-content max-content max-content;
        height: auto;
      }

      .KPIPerformance-sbti {
        grid-template-rows: 74px 24px max-content max-content max-content max-content max-content;
      }

      .KPIPerformance-2-col {
        grid-template-columns: 2fr 1fr 1fr 32px;

        .Separator {
          border-top: 1px solid $off-white-1;
          grid-column: 1 / span 4;
        }
      }
    }

    .GraphRow {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      margin: 0 32px 24px 32px;
      min-height: auto;
      flex-wrap: wrap;

      @media (max-width: 1366px) {
        height: auto;
      }

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        height: auto;
      }

      @media (min-width: 1920px) {
        flex-wrap: nowrap;
      }

      .GraphSideBarCard {
        flex: 1 1 0;
        border-radius: 8px;
        margin-right: 0;

        .CardHeading {
          padding: 16px 32px;
          border-bottom: 1px solid $off-white-1;
          margin: 0;

          h3 {
            display: inline;
          }

          .ProductsRecoveredTooltip {
            margin-left: auto;

            svg {
              width: 16px;
            }
          }
        }

        .ChartContainer {
          padding: 26px 32px 24px 32px;
        }
      }
    }

    .CardRow {
      display: flex;
      flex-direction: row;
      margin: 12px 32px 24px 32px;
      gap: 24px;
      height: fit-content;
      min-height: 350px;

      @media only screen and (max-width: 1060px) {
        flex-direction: column;
        height: 100%;
      }

      .Benchmarking__width {
        @media only screen and (min-width: 1061px) {
          max-width: calc(((100% / 3) * 2));
        }
      }

      .KPIPerformance__width {
        @media only screen and (min-width: 1061px) {
          max-width: calc((100% / 3) - 16px);
        }
        .KPIPerformance {
          .Heading {
            align-items: center;
          }
        }
      }

      .Card {
        @include card;
        flex-grow: 1;
        flex-basis: 0;
        padding-bottom: 24px;

        @media (min-width: 1024px) {
          .GraphSideBarCard .ChartWithYTitle {
            min-height: 150px;
          }
        }

        .CardHeading {
          padding: 16px 32px;
          border-bottom: 1px solid $grey-2;
          display: flex;
          justify-content: space-between;

          .Right {
            padding-right: 32px;
          }

          .Label {
            color: $grey-4;
            font-size: 11px;
            font-weight: normal;
            padding-left: 5px;
          }
        }

        .TabCard {
          margin-top: -1px;

          .CardHeading {
            display: none;
          }

          .TabBar {
            padding-left: 32px;
          }
        }

        .GraphSideBarCard,
        .LoadingSkeleton {
          box-shadow: none;
          padding: 24px 32px 0 32px;
          height: 200px;
        }

        .OpenModalLink {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          font-weight: 600;
          font-size: 12px;
          padding-left: 32px;

          & > div {
            padding-left: 12px;
          }
        }
      }
    }

    .DataSource-customer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #000000;
      font-size: 10px;
      margin-right: 32px;
      text-align: right;
      font-weight: 400;
      gap: 7px;

      .DataSource-customer__title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .resourse-icon__tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .resourse-icon__wrapper {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #111111;
      cursor: pointer;
      margin-right: 16px;
      box-sizing: border-box;

      &:hover {
        border: solid 2px #111111;
      }
    }
  }

  &.PppSales {
    .HFBSharesGraph {
      margin: 0;
      box-shadow: none;
      padding: 0;
      border-radius: 8px;

      .HFBHeading {
        margin-top: 24px;
      }

      .ChartContainer {
        margin: 0 24px;
        padding-bottom: 40px;
      }
    }

    .CardRow .Card .GraphSideBarCard {
      min-height: auto;
      height: auto;

      @media (min-width: 1024px) {
        .ChartWithYTitle {
          min-height: 200px;
        }
      }
    }

    .SeeMore {
      padding-top: 15px;

      > svg {
        margin-top: 15px;
      }
    }

    .CardRow .Card .CardHeading .Right {
      padding-right: 0;
    }

    .ChartContainer .ChartWithYTitle .Chart .YAxisText {
      display: flex;
      justify-content: flex-start;
    }
  }

  .tooltip {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (min-width: 1060px) {
    .three-in-row {
      .GraphSideBarCard {
        min-width: 200px;
        min-height: 335px;
      }
    }
  }

  @media (min-width: 1620px) {
    .three-in-row {
      .GraphSideBarCard {
        min-width: 200px;
      }
    }
  }

  @media (min-width: 1920px) {
    .three-in-row {
      .GraphSideBarCard {
        min-width: 200px;
      }
    }
  }

  .ClimateFootprintHeader-whithSBTi {
    .PageName {
      padding: 16px 32px;
    }
  }

  &__modal-header {
    .SkapaModal .modal-header {
      padding-left: 32px;
    }
  }
}

.BenchmarkingModalBody {
  display: grid;
  row-gap: 16px;
  border-top: 1px solid $off-white-1;
  padding-bottom: 0 !important;

  &.Values1 {
    grid-template-columns: 80% auto 32px;
  }

  &.Values2 {
    grid-template-columns: 60% 1fr 1fr 32px;
    text-align: end;
  }

  &.Values3 {
    grid-template-columns: 45% 1fr 1fr 1fr 32px;
    text-align: end;
  }

  &.Values4 {
    grid-template-columns: 30% 1fr 1fr 1fr 1fr 32px;
    text-align: end;
  }

  &.Values5 {
    grid-template-columns: 30% 1fr 1fr 1fr 1fr 1fr 32px;
    text-align: end;
  }

  &.Values6 {
    grid-template-columns: 40% 1fr 1fr 1fr 1fr 1fr 1fr 32px;
    text-align: end;
  }

  .BenchmarkingHeading {
    font-weight: 600;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $off-white-1;
  }

  .Bolded {
    font-weight: 600;
  }

  .YTD {
    @include ytd();
  }

  .FirstItem {
    padding: 0;
    text-align: start;
  }

  .BenchmarkingFooter {
    font-weight: 600;
    border-top: 1px solid $off-white-1;
    height: 64px;
    padding-top: 12px;
    background: $white;
    position: sticky;
    bottom: 0;
  }

  .OnTrack {
    color: $green;
  }

  .NotOnTrack {
    color: $red;
  }
}

.InlineMessageWrapper {
  margin: 24px 32px;
}

.Stripe {
  @include card();

  margin: 24px 32px 12px 32px;
  padding: 0 32px;
  height: 96px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .Title {
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 600;

    .Regular {
      font-weight: normal;
    }

    .arrowIcon {
      margin-left: 16px;
      cursor: pointer;

      &:hover {
        > path {
          fill: $black;
        }
      }
    }

    .tooltip {
      align-self: flex-start;
    }
  }
}

.DataSourceAndModalButton {
  display: flex;
  flex-direction: row;
  align-items: center;

  .DataSource {
    color: $black;
    font-size: 10px;
    margin-right: 32px;
    text-align: right;
  }
}

.MainCard {
  @include card();
  margin: 0 32px 24px 32px;

  .CardHeading {
    padding: 24px 32px;
    border-bottom: 1px solid $grey-2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .TitleExtantion {
      font-size: 12px;
      font-weight: 400;
      color: $grey-4;
      padding-left: 8px;
    }

    .tooltip svg {
      width: 16px;
      vertical-align: middle;
      margin-left: 16px;
    }

    .tooltip .Icon {
      width: 18px;
      height: 18px;
      margin-left: 18px;
      margin-top: 4px;
      cursor: pointer;
      vertical-align: middle;
    }

    .Filters {
      display: flex;
      align-items: center;
      font-weight: normal;

      .DropdownLabel {
        font-size: 10px;
        color: $black;
        font-family: inherit;
        letter-spacing: inherit;
        text-transform: inherit;

        margin-left: 24px;
        margin-right: 12px;
        padding: 0;
      }

      .DropdownOptionsMenu {
        min-width: 140px;
      }
    }

    .Subtitle {
      .Label {
        color: $grey-4;
        font-size: 11px;
        font-weight: normal;
        padding-left: 5px;
      }

      .Spacer {
        padding: 0 12px 0 8px;
      }
    }

    .CardHeading__coWorkers {
      margin-left: 12px;
      display: flex;
      gap: 12px;
    }
  }

  .Spacer {
    flex-grow: 1;
  }

  .Right {
    padding-right: 32px;
  }

  .GraphContainer {
    padding: 32px;
    height: 508px;
  }

  .Icon {
    fill: $grey-4;

    &:hover {
      fill: $black;
    }
  }
}

.TimeRangeSelector {
  text-transform: none;

  .TimeRangeSelectorMenu {
    .TimeRangeOption {
      text-align: center;
    }
  }
}

.SelectInsightAndFeedback {
  bottom: 32px;
}

.PppSales {
  .FilterSelector {
    @include card;
    margin: 24px 32px;
    padding: 24px 32px;
    background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1350px) {
      flex-wrap: wrap;
      row-gap: 24px;
    }

    .Left {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;

      @media only screen and (max-width: 1350px) {
        flex-wrap: wrap;
        row-gap: 24px;
      }

      .Title {
        font-size: 10px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 1px;
        color: #767676;
      }

      .FilterButtons {
        display: flex;
        flex-direction: row;
        margin-right: 12px;

        @media only screen and (max-width: 1024px) {
          flex-wrap: wrap;
          row-gap: 12px;
        }
      }

      .Button {
        background: $off-white-3;
        border-radius: 999px;
        font-size: 12px;
        font-weight: 600;
        padding: 12px 24px;
        margin-right: 8px;
        cursor: pointer;
        width: max-content;
        border: 1px solid transparent;

        &.Selected {
          border: 1px solid $black-1;
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    background-color: #f5f5f5;
  }

  50% {
    background-color: #ebe8e8;
  }

  100% {
    background-color: #f5f5f5;
  }
}

.Loading-square {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  border-radius: 2px;
  animation: blink 1.5s infinite;
}
