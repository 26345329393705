@import '../../Mixins.scss';
@import '../../Colours.module.scss';

.BenchmarkHeader {
  @include snapshot-section-header;
  grid-row: 7;
  grid-column: 1 / span 12;
}

.Benchmark {
  @include card-style;
  width: calc(100% - 64px);
  grid-row: 8;
  grid-column: 1 / span 12;
  margin-top: 8px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 32px;

  font-size: 12px;
  line-height: 20px;

  .HeadingLine {
    display: flex;
    flex-direction: row;
    padding: 12px 16px 8px 16px;
    border-bottom: 1px solid $off-white-1;

    h1 {
      margin: 0;
      line-height: 20px;
      font-size: 12px;
      font-weight: 700;
      padding-right: 8px;
    }

    .InformationIcon {
      height: 16px;
      width: 16px;
      padding-top: 1px;
    }
  }

  .Title {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
    color: $grey-4;
  }

  .ExpandButton {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .TopLevelSiteCountry {
    font-weight: bold;
  }

  .ResultNumber {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }

  .TableHeadTitle {
    display: flex;
    align-items: center;

    height: 40px;
    width: 100%;
    padding-left: 16px;
    box-sizing: border-box;

    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: $white;

    &.Health {
      background: $ppp-sales-snapshot;
    }

    &.Climate {
      background: $climate-footprint-gradient;
    }

    &.Social {
      background: $social-impact-gradient;
    }
  }

  .Table {
    display: grid;
    margin: 0;
    padding: 16px 16px 24px;
    align-items: center;
    grid-template-columns: 40px 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr;

    &.IsNarrow {
      grid-template-columns: 60px 2fr 2fr 1fr 1fr 1fr 1fr;
    }

    .KpiGroup {
      display: grid;
      grid-template-columns: auto 1fr;

      .Title {
        grid-row: 1;
      }

      h6,
      .KpiUnits {
        margin: 0;
        grid-row: 2;
        align-self: baseline;
        display: flex;
        align-items: center;
      }

      .KpiUnits {
        color: $grey-4;

        .SkapaTooltip {
          margin-left: inherit;
        }
      }

      h6 {
        margin-right: 4px;
      }

      &.planetenergy {
        padding-left: 16px;
      }
    }

    h5 {
      grid-row: 1;
      margin: 0;
    }

    .KPI,
    .ResultPenultimateFy,
    .ResultPrevFy,
    .Comparison {
      grid-row: 1;
      align-self: flex-end;
    }

    .ZeroBenchmark {
      align-self: center;
    }

    .Comparison {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h5,
    .ExpandButton {
      grid-column: 1;
      margin-left: 8px;
    }

    .KPI,
    .KpiGroup {
      grid-column: 2;

      .IconWrapper {
        height: 16px;
      }

      .Icon {
        fill: #767676;
        height: 16px;
        margin-left: 8px;

        &:hover {
          fill: #000000;
        }
      }
    }

    .SiteCountry,
    .Site {
      grid-column: 3;
    }

    .ResultPrevFy,
    .ResultPrevFyValue {
      grid-column: 4;
      text-align: center;
    }

    .ResultPrevFy {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Comparison,
    .ComparisonValue {
      grid-column: 5;
      text-align: center;
    }

    .GoalPrev,
    .GoalPrevValue {
      grid-column: 6;
      text-align: center;
    }

    .Goal,
    .GoalValue {
      grid-column: 7;
      text-align: center;
    }

    .GoalNext,
    .GoalNextValue {
      grid-column: 8;
      text-align: center;
    }

    .GridHr {
      grid-column: 1 / span 8;
      border-bottom: 1px solid $off-white-1;
      margin: 24px 0;

      &.Head {
        margin-top: 16px;
      }

      &.Site {
        grid-column: 2 / span 6;
        margin: 8px 0;
        border-bottom: none;
      }
    }

    .GridHr + .GridHr {
      display: none;
    }

    .LoadingSkeleton {
      height: 34px;
      grid-column: 1 / span 8;
    }
  }
}

.MarginLeftRightBottom32 {
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 32px;
}
