@import '../Colours.module.scss';

.Prompt {
  position: relative; /* Works around a Skapa z-index issue */
  background: $white;
  box-shadow: 0px 18px 32px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 500px;
  text-align: center;

  :global(.prompt__heading) {
    padding: 1.5rem;
    border-bottom: 2px solid $off-white-1;
    font-size: 16px;
    color: $black-1;
  }

  .FixedHeightContainerLeaderboard {
    height: 320px;
  }

  .FixedHeightContainerSnapshot {
    height: 300px;
  }

  :global(.modal-body) {
    padding: 0;
  }

  .PromptContent {
    padding: 32px 64px 0px 64px;

    p:first-child {
      margin-top: 0;
    }

    .ImageAboveText {
      margin-top: 16px;
    }

    .ImageBelowText {
      margin-top: 28px;
    }
  }

  :global(.SelectSlide) {
    margin-top: 50px;
    margin-bottom: 24px;
  }

  .PromptFooter {
    display: grid;
    grid-template-columns: repeat(3, [col] 1fr);
    grid-template-rows: 100% row;

    line-height: 3.5rem;
    font-size: 14px;
    padding-bottom: 32px;

    button {
      min-width: 150px;
      grid-column: 2;
    }

    .BackButton {
      grid-column: 1;
    }

    .CloseColumn {
      grid-column: 3;
    }

    div {
      span {
        cursor: pointer;
      }
    }

    button,
    div {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}
