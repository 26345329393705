@import '../Colours.module.scss';

.Slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 22px;

  .SliderLine {
    height: 1px;
    width: 100%;
    background-color: $grey-4;
  }

  .Indicator {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: $blue-3;
    border-radius: 50%;

    &.Animated {
      transition: left 0.2s ease-in-out;
    }
  }

  .OverlayIndicator {
    position: absolute;
    width: 2px;
    height: 28px;
    background-color: $salmon-1;
  }
}
