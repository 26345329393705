@import '../Colours.module.scss';

.CardContainer {
  flex: 1 1;
  border-radius: 8px;
  margin-right: 0px;
  height: 100%;

  .CardHeading {
    .Right {
      padding-right: 0px !important;

      .Unit {
        font-size: 11px;
        font-weight: 400;
        color: $grey-4;
      }
    }
  }

  &.IntensitySold {
    .ChartContainer {
      .Legend {
        .LegendItems {
          grid-template-columns: repeat(auto-fit, minmax(50px, min-content));
        }
      }
    }
  }

  .ChartContainer {
    padding: 26px 32px 24px 32px;
    height: calc(100% - 26px);

    .ChartWithYTitle {
      height: 100%;
      justify-content: center;
    }

    .Legend {
      .LegendItems {
        .Item {
          font-size: 12px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.ClimateIntensityBenchmarkGraphContainer {
  margin: 0 24px;
  padding-bottom: 30px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  align-items: flex-start;

  .ChartContainer {
    width: 100%;

    .Chart {
      margin-top: 16px;

      .xAxisStroke {
        stroke: $grey-2;
      }
    }

    .SiteLink {
      font-size: 14px;
    }
  }

  .SeeMore {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .Link {
      margin-left: 8px;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .Tooltip {
    .Heading {
      span {
        align-self: flex-end;

        &:first-child {
          overflow: visible;
          white-space: normal;
        }
      }
    }
  }

  .ClimateIntensityBenchmarkLegendContainer {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 16px;
    font-size: 14px;

    .CurrentPeriodIndicator {
      fill: $salmon;
      margin-right: 8px;
      align-self: center;
    }

    .LastPeriodIndicator {
      display: flex;
      align-items: center;
      border-right: 2px solid $black;
      height: 18px;
      margin-left: 16px;
      margin-right: 8px;

      svg {
        fill: $light-pink;
      }
    }
  }
}
