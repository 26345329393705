@import '../Colours.module.scss';

.Header {
  height: 116px;
  mix-blend-mode: normal;

  .PageName {
    padding: 24px 32px;
    font-size: 14px;
    color: $white;

    svg {
      margin-right: 8px;
    }
  }

  &.Link {
    .PageName {
      padding-left: 49px;
    }
  }

  &.ClimateFootprintHeader {
    background: $climate-footprint-gradient;

    .PageName {
      display: flex;
      justify-content: space-between;

      .btn--scope,
      .btn--sbti {
        font-size: 0.75rem;
        padding: 0.5rem 1.5rem;
        border: 1px solid white;
        cursor: pointer;
        color: white;
        z-index: 2;
        min-width: 100px;
        justify-content: center;
        align-items: center;
      }

      .btn--scope,
      .btn--sbti {
        border-radius: 64px 0 0 64px;

        &.active {
          color: black;
          background: white;
        }
      }

      .btn--sbti {
        border-radius: 0 64px 64px 0;
      }
    }
  }

  &.circular-revenue__header {
    background: $zero-waste-gradient;
  }

  &.SocialImpactHeader {
    background: $social-impact-gradient;
  }

  &.SustainabilityMentionsHeader {
    background: $sustainability-mentions-gradient;
  }

  &.PppSalesHeader {
    background: $ppp-sales-gradient;
  }

  &.ZeroWasteHeader {
    background: $zero-waste-gradient;
  }
}
