.Breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Title {
  font-weight: bold;
  cursor: default;

  &:hover {
    text-decoration: none;
  }
}

.ArrowIcon {
  margin: 0 8px;
  width: 12px;
  height: 12px;
}
