@import '../../../Colours.module.scss';

.MainCardSummary {
  display: flex;
  gap: 32px;

  > div {
    border-right: 1px solid $grey-2;
    padding-right: 32px;

    &:last-child {
      border: none;
      padding: 0;
    }
  }
}

.TabBar {
  padding: 0 32px;

  .Summary {
    padding: 16px 0;
    font-size: 14px;

    .Amount {
      font-weight: 700;
    }
    .Unit {
      font-size: 11px;
      font-weight: 400;
      color: $grey-4;
    }
  }
}

.StatsCardContainer {
  height: 100%;
  box-sizing: border-box;
  margin: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  .StatsRow {
    display: flex;
    align-items: center;
    gap: 10px;

    .Dot {
      border-radius: 999px;
      height: 10px;
      width: 10px;
      display: inline-block;
    }
    .Title {
      flex-grow: 1;
    }
    .Value {
      font-family: Roboto Mono;
    }
  }
}

.Tooltip {
  height: 20px;
}

.TooltipIcon {
  width: 20px;
  height: 20px;
}
