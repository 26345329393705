@import '../KPIPages/KPIPage.scss';
@import '../../Colours.module.scss';

.ExploreGraphCard {
  @include card;
  margin: 24px 32px;

  .ExploreGraphHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid $grey-2;
    padding: 24px;

    .Left,
    .TotalAmountContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
        margin-left: 18px;
        margin-top: 4px;
        cursor: pointer;
      }
    }

    .TotalAmount {
      margin-left: 10px;

      .Unit {
        font-size: 11px;
        font-weight: 400;
        color: $grey-4;
        margin-left: 8px;
      }
    }
  }

  .ExploreGraphContainer {
    padding: 32px;
    height: 556px;

    @media only screen and (max-width: 1024px) {
      height: 100%;
    }

    .ChartContainer {
      .Legend {
        padding-top: 32px;
        padding-bottom: 0;
      }
    }
  }

  .Icon {
    fill: $grey-4;

    &:hover {
      fill: $black;

      path {
        fill: $black;
      }
    }
  }
}
