@import '../../Mixins.scss';
@import '../../Colours.module.scss';

.TimeRangeSelector {
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  position: relative;

  .TimeRangeSelector-text {
    display: flex;
    justify-content: flex-end;
    text-align: end;
  }

  .Ytd {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-transform: none;
    cursor: pointer;
  }

  svg {
    margin-left: 6px;
  }

  .TimeRangeSelectorMenu {
    position: absolute;
    top: 24px;
    right: 0;
    background: $white;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0), 0px 4px 20px rgba(0, 0, 0, 0);
    border: 1px solid transparent;
    border-radius: 4px;
    z-index: 2;
    width: 150px;
    min-width: fit-content;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out 0.1s;
    display: none;

    &.Open {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid $off-white-1;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);
      max-height: 500px;
    }

    .TimeRangeOption {
      padding: 16px;
      width: 100%;
      min-width: fit-content;

      &.Selected {
        background: $grey-1;
      }
    }
  }
}

.ClickCatcher {
  @include click-catcher;
}
