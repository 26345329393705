@import '../Mixins.scss';
@import '../Colours.module.scss';

.HFBSharesGraph {
  @include card-style;
  @include responsive-padding;
  @include graph-description-row;

  margin-left: 16px;
  margin-right: 16px;

  grid-row: 4;
  grid-column: col 1 / span 9;

  h2 {
    font-size: 18px;
    flex-grow: 2;
  }

  .HFBDescription {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .HFBHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 16px;

    .HFBLegend {
      @include hfblegend;
    }
  }

  .FlowSelector {
    display: flex;
    flex-direction: row;

    .FlowOption {
      display: flex;
      flex-direction: row;
      padding-right: 10px;
      align-items: center;

      .FlowName {
        padding-left: 6px;
      }
    }
  }

  .ChartContainer {
    .Chart {
      margin-top: 16px;
    }

    .HFBLink {
      display: flex;
      flex-direction: row;
      color: $grey-4;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: black;

        .HFBInformation {
          background: $off-white-2;
        }
      }

      .HFBInformation {
        background: $grey-1;
        cursor: pointer;
        height: 24px;
        width: 24px;
        border-radius: 16px;
        margin-right: 8px;

        svg {
          margin-top: 6px;
          margin-left: 6px;
          width: 12px;
        }
      }
    }

    .xAxisTitle {
      font-size: 10px;
    }

    .Tooltip {
      .Heading {
        span {
          align-self: flex-end;

          &:first-child {
            overflow: visible;
            white-space: normal;
          }
        }
      }

      .PreviousPeriodTooltipIndicator {
        border-left: 2px solid black;
        margin-left: 7px;
      }
    }
  }

  .TopSellingArticlesOverlayBody {
    padding: 0 3rem;
  }

  &.NewKpi {
    .NewKpi-icon {
      display: flex;
    }
    .Tooltip {
      margin-top: -160px;
    }
  }
}
