@import '../Colours.module.scss';

.RadioButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
  margin-left: 32px;

  & ~ .RadioButton {
    margin-left: 0;
  }

  .Toggle {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $blue-2;
    margin-right: 12px;

    &.Selected::before {
      display: inline-block;
      position: absolute;
      margin-left: 2px;
      margin-top: 2px;
      content: '';
      width: 12px;
      height: 12px;
      background-color: $blue-2;
      border-radius: 50%;
    }
  }
}
