@import '../Colours.module.scss';

.FeedbackModal {
  @mixin input-style {
    margin: 0 32px 10px 32px;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid $grey-4;
    font-family: inherit;
  }

  .Email {
    text-align: center;
    font-size: 10px;
    color: $grey-4;
  }

  label {
    display: block;
    font-size: 10px;
    font-weight: 700;
    color: $grey-4;
    text-transform: uppercase;
    margin: 20px 32px 10px 32px;
  }

  input {
    @include input-style;
    box-sizing: border-box;
    width: calc(100% - 64px);
  }

  textarea {
    @include input-style;
    width: calc(100% - 64px);
    resize: none;
    margin-bottom: 40px;
    box-sizing: border-box;
  }

  .SignupCheckBox {
    display: flex;
    flex-direction: row;
    margin: 0 32px 32px 32px;
    align-items: center;

    .CheckboxContainer {
      align-self: baseline;
      padding-top: 5px;
    }

    .SignupLabel {
      padding-left: 16px;
      font-size: 12px;
    }
  }

  .SendFeedbackButton {
    display: block;
    margin: 0 auto;
    width: 226px;

    span {
      box-sizing: border-box;
    }

    .btn__inner {
      box-sizing: border-box;
    }
  }

  .FeedbackSent {
    text-align: center;
    margin-top: 20px;
  }
}
