@import '../../Mixins.scss';
@import '../../Colours.module.scss';

.MultiSelectContainer {
  position: relative;
  font-size: $font-size-menu-nav;

  &.disabled {
    opacity: 0.3;
  }
}

.MultiSelectSelection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.MultiSelectMenu {
  position: absolute;
  top: 100%;
  margin-top: 4px;
  margin-bottom: 8px;
  border-radius: 4px;
  width: 100%;
  z-index: 2;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  line-height: 22px;

  .ItemContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    height: 56px;
    border-bottom: 1px solid $off-white-1;

    &.disabled > * {
      opacity: 0.3;
    }

    &.isTopLevel {
      font-weight: bold;
    }

    &:not(.isTopLevel) {
      padding-left: 40px;
    }

    .MultiSelectMenuItem {
      height: 100%;
      display: flex;
      align-items: center;
      user-select: none;
    }
  }
}

.ClickCatcher {
  @include click-catcher;
}
