@import '../Mixins.scss';
@import '../Colours.module.scss';

.SnapshotTopCardsHeader {
  @include snapshot-section-header;
  grid-row: 2;
  grid-column: 1 / span 12;
}

.SnapshotTopCards,
.SnapshotTopCardsSmall {
  margin: 0 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  // @media only screen and (min-width: 1920px) {
  //   grid-template-columns: repeat(5, 1fr);
  // }

  @media only screen and (max-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 748px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.SnapshotLink {
  position: relative;
  &:hover {
    text-decoration: none !important;
  }
}

.warning-wrapper {
  .SnapshotCardWarning {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px;
    border-inline-start: none;
  }
}

.Row3 {
  grid-row: 3;
}

.Row4 {
  grid-row: 4;
}

.SnapshotTopCardsSmall {
  grid-row: 4;
}

.SnapshotGraphContainer {
  display: flex;
}

.SnapshotLabelAndCard {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  position: relative;
  flex-grow: 1;

  .SnapshotCardLabel {
    border-radius: 8px;
    margin: 12px;
    margin-bottom: 0px;
    height: 38px;
    color: $white;
    display: flex;
    align-items: center;

    .LabelText {
      margin-left: 16px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.3px;
    }
  }

  .iae-kpi {
    background: $healthy-living-snapshot;
  }

  .zerowaste-kpi {
    background: $zero-waste-snapshot;
  }

  .climate-kpi {
    background: $climate-footprint-snapshot;
  }

  .social-impact-kpi {
    background: $social-impact-snapshot;
  }

  .ppp-sales-kpi {
    background: $ppp-sales-snapshot;
  }

  .sustainability-mentions-kpi {
    background: $sustainability-mentions-snapshot;
  }

  .SnapshotCard,
  .SnapshotCardSmall {
    @include card-style;
    @include clickable-card;

    margin: 12px;

    .SnapshotCardHeader {
      border-bottom: 1px solid $grey-1;
      padding: 12px 16px 8px 16px;
      display: flex;
      justify-content: space-between;

      h3 {
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
      }
    }

    .Description {
      display: flex;
      flex-direction: row;
      font-weight: 700;
      color: $black;

      .Unit {
        font-size: 10px;
        text-transform: uppercase;
        padding-left: 6px;
      }

      .Icon {
        margin-left: auto;
        width: 16px;
        height: 16px;
      }

      &.OnTrack {
        color: $green;
      }
      &.NotOnTrack {
        color: $red;
      }
    }

    .SnapshotCardInformation > span {
      z-index: 10;
      filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1)), drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
      border-radius: 4px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .SnapshotCard {
    min-width: 325px;
    flex: 1;

    .Graph {
      padding-top: 8px;
      padding-left: 16px;
      height: 150px;

      .ChartContainer {
        .SafariWorkaroundContainer {
          height: 150px;
          .Chart {
            height: 150px;
          }
        }
      }
    }

    .NoDataViewSmall {
      @media (max-width: 1280px) {
        height: 108px + 50px;
      }

      .Image {
        padding-bottom: 24px;
        padding-top: 12px;
        padding-left: 16px;
        padding-right: 8px;
      }

      .Text {
        padding: 12px;
        padding-right: 16px;
        padding-left: 8px;
        padding-bottom: 24px;
        margin-right: 0px;
      }
    }

    .Description {
      padding: 8px 16px 4px 16px;

      .Value {
        font-size: 28px;
      }

      .Unit {
        padding-top: 6px;
        letter-spacing: 1px;
      }

      .Icon {
        padding-top: 6px;
      }
    }

    .TextAndIcons {
      display: flex;
      flex-direction: column;
      padding: 8px 16px 4px 16px;
      font-size: 12px;
      line-height: 18px;
      color: $black;

      .Icons {
        img {
          width: 30%;
          filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.1));
        }
      }
    }

    .LoadingSkeleton {
      @media (max-width: 1280px) {
        height: 158px;
      }

      height: 208px;
    }

    .SnapshotCardFooter {
      border-top: 2px solid $off-white-1;
      text-align: left;
    }
  }

  .SnapshotCardSmall {
    .Description {
      padding: 8px 16px 8px 16px;

      .Value {
        font-size: 16px;
      }

      .Unit {
        padding-top: 3px;
      }

      .Icon {
        padding-top: 1px;
      }
    }

    .Body {
      font-size: 14px;
      padding: 8px 16px 8px 16px;
    }

    .LoadingSkeleton {
      height: 38px;
    }
  }
}
