@import '../Colours.module.scss';

.Toggle {
  padding-top: 8px;
  float: right;
  height: 24px;

  &.Disabled {
    .Toggler {
      cursor: default;
      background: $off-white-1;
    }
  }

  .Value {
    font-size: 12px;
    vertical-align: super;
    user-select: none;
  }

  .Toggler {
    display: inline-block;
    width: 48px;
    height: 100%;
    background: $yellow;
    border-radius: 24px;
    cursor: pointer;
    margin-left: 6px;
    margin-right: 6px;

    &::before {
      content: '';
      position: relative;
      display: block;
      width: 18px;
      height: 18px;
      top: 3px;
      border-radius: 50%;
      background-color: $white;
      transition: transform 0.2s;
    }

    &.Left::before {
      transform: translateX(4px);
    }

    &.Right::before {
      transform: translateX(26px);
    }
  }
}
