@import '../Mixins.scss';

.TopBar {
  display: flex;
  grid-column: col 1 / span 12;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: white;
  color: black;
  margin-bottom: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

  .KPIFunctionSelector {
    line-height: 56px;
    border-right: $border-gray;
    width: 22.5%;

    .MultiSelectMenuItem {
      color: black;
    }
  }

  .LocationSearch {
    display: flex;
    height: 100%;
    border-right: $border-gray;
    font-size: 12px;
    line-height: 24px;
    width: 22.5%;
  }

  .ExploreButton {
    margin-left: 16px;
  }

  .NotificationsAndFeedback {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 32px;
    margin-left: auto;

    .TopBarTooltip {
      font-weight: 700;
    }

    .Notification {
      height: 24px;
      margin-left: 8px;
      margin-right: 16px;

      svg {
        cursor: pointer;
      }
      .tooltip {
        display: inline-block;
      }
      &.Unread::after {
        @include unread;
      }
    }

    .GiveFeedbackLink {
      svg {
        cursor: pointer;
        width: 32px;
        height: 32px;
        margin-right: 24px;
      }
    }
  }
}
