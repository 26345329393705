@import '../../Mixins.scss';
@import '../../Colours.module.scss';

.InstorePage {
  background-color: $white;

  .AnimatedContainer {
    transition: opacity 500ms ease-in-out;
    display: grid;
    height: 100vh;
    overflow: hidden;

    &.in {
      opacity: 1;
    }

    &.exiting {
      opacity: 0;
    }

    &.entering {
      opacity: 0;
    }

    .FootprintInsightDataSource {
      position: absolute;
      color: $grey-4;
      font-size: 20px;
      left: 100px;
      bottom: 60px;
    }
  }
}
