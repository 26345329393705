@import '../../../Colours.module.scss';

.InstoreConfiguratorPage {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  height: 100vh;

  @media only screen and (max-width: 1024px) {
    display: block;
  }

  .LeftPane {
    background: radial-gradient(75.09% 217.11% at 20.92% 78.69%, #ffcb45 0%, $yellow 100%);
    mix-blend-mode: normal;
    text-align: center;
    display: flex;
    flex-direction: column;

    .Title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 48px;
      font-weight: bold;
      line-height: 60px;
      padding: 16vh 0 40px 0;

      @media only screen and (max-width: 1024px) {
        padding-top: 80px;
      }

      .Logo {
        width: 60px;
        height: 60px;
        margin-bottom: 36px;
      }
    }

    .LinkToFrontpage {
      padding-bottom: 20vh;

      @media only screen and (max-width: 1024px) {
        padding-bottom: 40px;
      }
    }

    .Description {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 100px;

      .InstructionsLink {
        padding: 30px 0 20px 0;
      }
    }

    .Link {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    background-color: $white;
    padding-bottom: 16px;

    h5 {
      font-size: 16px;
      margin: 48px 0 20px;
    }

    .LocationSearch {
      background-color: $white;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 28px;
      height: 56px;
      padding: 10px;
      pointer-events: all;
      width: 50%;

      .LocationSearch__menu {
        border-radius: 28px;
        overflow: hidden;
        top: 70px;
        left: 0;

        .LocationSearch__menu-list {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .UnitSelector {
      height: 56px;
      width: 50%;

      &Container {
        width: 100%;
        height: 100%;
        font-size: 16px;
        margin: 0;
      }

      &__control {
        height: 100%;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 28px;
        padding: 16px 28px 16px 16px;
      }
    }

    .SlideList {
      width: 50%;
      position: relative;

      .SlideGroupName {
        position: absolute;
        left: -70px;
        transform: translateY(46px);
        font-weight: bold;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.2px;
        text-transform: uppercase;

        &:first-child {
          transform: translateY(18px);
        }
      }

      .SlideListItem {
        display: flex;
        flex-direction: row;
        border: 1px solid $off-white-1;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 54px;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        margin-bottom: 4px;
        margin-top: 28px;

        &.isSameGroup,
        &:nth-child(2) {
          margin-top: 0;
        }

        .CheckBox {
          border: 1px solid $off-white-1;
        }
      }
    }

    .GenerateLinkButton {
      background-color: black;
      color: $white;
      margin-top: 100px;

      &[disabled] {
        color: $grey-3;
      }
    }
  }
}
