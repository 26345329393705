@import '../Mixins.scss';
@import '../Colours.module.scss';

$section-margin-left: 12vw;

.ArrowLink > svg {
  height: 24px;
  vertical-align: bottom;
  margin-right: 8px;
}

.Section {
  .Subtitle {
    font-size: $font-size-menu-nav;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }
}

.InTheSpotlight {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 120px;
  text-align: center;

  p {
    font-size: 22px;
    margin-bottom: 80px;
  }
}

.Section.Heading {
  background: radial-gradient(75.09% 217.11% at 20.92% 78.69%, #ffcb45 0%, $yellow 100%);
  padding-left: $section-margin-left;
  padding-right: 77px;
  padding-bottom: 72px;

  .TopRow {
    display: flex;
    left: 60px;
    position: absolute;
    top: 50px;

    h2 {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      margin: 0;
      text-transform: uppercase;

      svg {
        margin-right: 14px;
        vertical-align: middle;
      }
    }

    .Links {
      display: flex;
      font-size: $font-size-menu-nav;
      font-weight: bold;
      line-height: 24px;
      list-style-type: none;
      margin: 0;
      padding-left: 0;

      li {
        margin-left: 32px;
      }

      button {
        @include for-smaller-screen {
          display: none;
        }
      }
    }
  }

  .FourPsHeading {
    font-size: 58px;
    line-height: 68px;
    padding-top: 200px;
    text-align: left;

    @media only screen and (max-width: 768px) {
      font-size: 40px;
      line-height: 40px;
      padding-top: 150px;
    }
  }

  p {
    font-size: 18px;
    line-height: 26px;
    margin-top: 40px;
    max-width: 620px;
  }

  .ScrollDownForMore {
    font-size: 16px;
    margin-top: 180px;

    @media only screen and (max-width: 768px) {
      margin-top: 40px;
    }

    svg {
      height: 16px;
      margin-left: 10px;
      transform: rotate(90deg);
      vertical-align: middle;
      width: 16px;

      circle {
        fill: $black;
      }

      path {
        fill: $yellow-2;
      }
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: $font-size-menu;

    &:focus {
      outline: none;
    }
  }
}

.Section.BigThree {
  padding: 100px 12vw 120px;

  .KPIContainer {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      row-gap: 20px;
    }

    .KPI {
      background-color: white;
      flex-basis: 0;
      flex-grow: 1;
      margin-left: 30px;
      max-width: 33%;
      position: relative;
      text-align: left;

      @media only screen and (max-width: 1024px) {
        max-width: 100%;
        width: 100%;
        margin-left: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      img {
        width: 100%;
      }

      h2 {
        font-size: 18px;
        font-weight: bold;
        padding: 0 30px;
      }

      p {
        font-size: $font-size-menu-nav;
        line-height: 22px;
        margin-bottom: 104px;
        padding: 0 30px;
      }

      .ArrowLink {
        position: absolute;
        bottom: 30px;
        left: 30px;
      }
    }
  }
}

.Section.KPIGraphs {
  background-color: $off-white-2;
  color: $grey-7;

  .GraphContainer {
    margin: auto;
    max-width: 1240px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 80px;

    .SnapshotLabelAndCard {
      width: 100%;
    }

    @media only screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);

      .SnapshotLabelAndCard {
        width: 100%;
        @media only screen and (max-width: 1024px) {
          min-height: 500px;
        }

        .SnapshotCard {
          height: 100%;

          .Graph {
            height: 100%;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .LeaderboardTopPanel {
    margin: 0 $section-margin-left 40px;

    @media only screen and (max-width: 768px) {
      margin: 0 20px;
      margin-bottom: 20px;
    }
  }
}

.Section.HowTo,
.Section.BigThreeIntroduction {
  background-color: $white;
}

.Section.BigThree,
.Section.BigThreeIntroduction,
.Section.MarketManagers,
.Section.HowTo,
.Section.FAQ {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;

  @media only screen and (max-width: 1024px) {
    padding: 80px 0;
  }

  p {
    font-size: 22px;
    margin-bottom: 80px;

    @media only screen and (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  a {
    font-size: 18px;
  }
}

.Section.FAQ {
  background-color: white;
  padding: 100px $section-margin-left;

  .accordion {
    p {
      margin-bottom: 14px;
    }
    p,
    li {
      font-size: $font-size-menu-nav;
      line-height: 22px;
      text-align: left;
    }
  }
}

.StickyLocationSelector {
  display: flex;
  min-height: 120px;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: background 0.5s ease-in-out;
  align-items: center;
  padding: 0 80px;
  width: calc(100% - 160px);
  justify-content: space-between;

  @media only screen and (max-width: 894px) {
    padding: 20px 40px;
    width: calc(100% - 80px);
    justify-content: flex-end;
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }

  @media only screen and (max-width: 768px) {
    min-height: 0px;
  }

  &.isFixed {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.1);
    justify-content: flex-end;

    h2 {
      display: none;
    }

    p {
      @media only screen and (min-width: 1280px) {
        display: block;
      }
    }
  }

  .SelectorContainer {
    display: flex;

    @media only screen and (max-width: 700px) {
      flex-wrap: wrap;
      row-gap: 10px;
    }
  }

  h2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    max-width: 491.312px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    text-transform: uppercase;

    @media only screen and (max-width: 700px) {
      display: none;
    }

    svg {
      margin-right: 14px;
      @media only screen and (max-width: 700px) {
        display: none;
      }
    }

    span {
      @media only screen and (max-width: 894px) {
        display: none;
      }
    }
  }

  p {
    display: none;
    font-size: 18px;
    line-height: 120px;
    flex-grow: 0;
    white-space: pre;
  }

  .FunctionAreaSelect {
    max-width: 180px;
    width: 100%;
    min-width: 180px;

    .FunctionAreaSelect__option {
      padding-left: 27px;
    }
  }

  .LocationSearch {
    max-width: 360px;
    min-width: 180px;
    width: 100%;

    @media only screen and (max-width: 700px) {
      width: auto;
    }
  }

  .LocationSearch,
  .FunctionAreaSelect {
    background-color: $white;
    border-radius: 999px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    height: 56px;
    margin-right: 16px;
    padding: 10px;
    pointer-events: all;

    @media only screen and (max-width: 700px) {
      height: 40px;
      width: 150px;
    }

    .LocationSearch__menu,
    .FunctionAreaSelect__menu {
      border-radius: 28px;
      overflow: hidden;
      top: 70px;
      left: 0;
      font-size: $font-size-menu-nav;
      padding: 8px 0;

      .LocationSearch__menu-list,
      .FunctionAreaSelect__menu-list {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  button {
    @include blue-button;
    margin-top: 0;
    margin-right: 0;

    @media only screen and (max-width: 700px) {
      width: 100px;
      height: 40px;
    }
  }
}

.Footer {
  background-color: $white;
  display: flex;
  justify-content: space-between;

  svg {
    margin: 32px 80px;
  }

  .Feedback {
    color: $grey-4;
    font-size: 16px;
    line-height: 104px;
    margin-right: 80px;

    .Link {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
