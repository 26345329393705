@import '../Colours.module.scss';

.GraphSideBarCard {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: white;
  height: fit-content;
  flex: 1 0 26%;

  .ChartWithYTitle {
    min-height: 200px;
  }

  &:last-child {
    margin-right: 0;
  }

  .CardHeading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
    height: 40px;

    &.WithSpace {
      justify-content: space-between;
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      margin-right: 8px;
    }

    .Amount {
      font-weight: 700;
    }

    .Unit {
      font-size: 11px;
      font-weight: 400;
      color: $grey-4;
    }

    .HFBHelpText {
      margin-right: 0;
      margin-left: auto;

      & > span {
        width: 14px;
        height: 14px;
      }
    }

    .deliveries {
      width: 45%;

      @media (max-width: 1610px) {
        width: 55%;
      }

      @media (max-width: 1217px) {
        width: 45%;
      }
    }
  }

  .Subheading {
    font-size: 14px;
    line-height: 22px;
  }

  .Graph {
    padding: 16px 0;
    text-transform: none; // override setting from generic graph SCSS
  }

  .NoDataViewSmall {
    height: 335px - 52px; // Total card height minus header
  }

  .BestSite {
    font-size: 12px;

    a {
      text-decoration: underline;
      font-weight: bold;
      color: $grey-4 !important;
    }

    .Share {
      font-weight: bold;
    }
  }

  .Rank {
    display: flex;
    flex-direction: row;

    .Ordinal {
      color: $salmon-1;
      font-weight: bold;
      display: flex;
      padding-right: 8px;

      .Ranking {
        font-size: 48px;
      }

      .Suffix {
        vertical-align: text-top;
        font-size: 12px;
        line-height: 42px;
      }
    }

    .Body {
      font-size: 12px;
      line-height: 18px;
      padding-top: 12px;
    }
  }

  .ChartContainer {
    height: 100%;
    .Legend {
      .LegendItems {
        grid-template-columns: repeat(auto-fit, minmax(60px, max-content));

        .Item {
          font-size: 12px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  &.ShareGraphCard {
    position: relative;

    .ShareGraph {
      max-height: 198px;
      display: flex;
      flex-direction: column;

      .SimpleLegend {
        margin-top: 24px;
        font-size: 14px;
      }
    }
  }

  .OpenModalLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;

    & > div {
      padding-right: 12px;
    }
  }

  &.List {
    justify-content: space-between;
  }
}

.GraphSideBarCard {
  min-width: 500px;
  min-height: 335px;
}

.water-site-level {
  .CardHeading {
    justify-content: flex-start;

    .SkapaTooltip {
      margin-left: 0;

      .Icon {
        margin-top: 0;
        margin-left: 4px;
      }
    }
  }

  .water-site-content {
    display: flex;
    width: 100%;
    height: 225px;
    justify-content: center;
    align-items: center;

    .stress-level {
      display: flex;
      justify-content: flex-start;
      width: 320px;
      border-radius: 8px;
      font-size: 24px;
      font-weight: 700;
      padding: 24px;
    }

    .low {
      background: #00853e;
      color: #ffffff;
    }

    .low-medium {
      background: #5cc27c;
    }

    .medium-high {
      background: #ffdb00;
    }

    .high {
      background: #ec773e;
    }

    .extremely-high {
      background: #cc0008;
      color: #ffffff;
    }
  }
}

@media (max-width: 1610px) {
  .water-site-level {
    .water-site-content {
      .stress-level {
        width: 200px;
        font-size: 20px;
        padding: 20px;
      }
    }
  }
}

@media (min-width: 1610px) {
  .GraphSideBarCard {
    min-width: 650px;
  }
}

@media (min-width: 1920px) {
  .GraphSideBarCard {
    min-width: auto;
    min-height: 335px;
  }
}

.TopArticlesList {
  margin-top: 0;
  margin-bottom: 14px;
  padding-left: 14px;
  font-size: 12px;
  font-weight: 600;

  li {
    padding-bottom: 4px;
  }
}

.TopArticlesTable {
  display: grid;
  grid-template-columns: 30px 1fr 200px;
  padding: 24px 32px 0;

  div {
    height: 38px;

    &.Sales {
      text-align: right;
    }
  }
}

.NoDataWrapper {
  height: calc(100% - 32px);
  display: flex;
  justify-content: center;
  align-items: center;
}
