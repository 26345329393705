@import '../../KPIPages/KPIPage.scss';
@import '../../../Colours.module.scss';

.KPIPage {
  .PageContent {
    .FilterSelector {
      @include card;
      margin: 24px 32px;
      padding: 24px 32px;
      background-color: $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 1350px) {
        flex-wrap: wrap;
        row-gap: 24px;
      }

      .Left {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;

        @media only screen and (max-width: 1350px) {
          flex-wrap: wrap;
          row-gap: 24px;
        }

        .Title {
          font-size: 16px;
          line-height: 42.5px;
          font-weight: 600;
          margin-right: 24px;
        }

        .FilterButtons {
          display: flex;
          flex-direction: row;
          margin-right: 12px;

          @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
            row-gap: 12px;
          }
        }

        .Button {
          background: $off-white-3;
          border-radius: 999px;
          font-size: 12px;
          font-weight: 600;
          padding: 12px 24px;
          margin-right: 8px;
          cursor: pointer;
          width: max-content;
          border: 1px solid transparent;

          &.Selected {
            border: 1px solid $black-1;
          }
        }

        .RadioButtonContainer {
          display: flex;
          flex-direction: row;

          .RadioButton {
            margin-left: 0;

            .Label {
              font-size: 12px;
            }
          }
        }
      }

      .Right {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 400px;
        justify-content: flex-end;

        @media only screen and (max-width: 1024px) {
          width: 100%;
          justify-content: flex-start;
        }

        .TimelineHeader {
          font-size: 10px;
          margin-right: 4px;
        }

        .FilterDropdownMenu {
          width: min(168px, 100%);
          margin: 0 12px;

          .DropdownLabel {
            padding: 0;
          }
        }

        .FYSeparator {
          width: 12px;
          height: 1.5px;
          background-color: $black;
        }
      }
    }

    .Legend {
      .AnnualLegend {
        grid-template-columns: repeat(auto-fit, minmax(1px, min-content));

        .Separator {
          width: 1px;
          height: 20px;
          background: #cccccc;
        }
      }
    }
  }
}
