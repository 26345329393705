@import '../Colours.module.scss';

.UnitSelector {
  display: flex;

  .UnitSelectorContainer {
    margin-top: 20px;
    margin-left: 20px;
    min-width: 208px;
    font-size: 12px;

    .UnitSelector__option:not(:last-child) {
      border-bottom: 1px solid $off-white-1;
    }
  }
}
