@import '../../Colours.module.scss';

.InsightsModal {
  .theatre {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .modal-header {
    background-color: transparent;
  }

  .InsightsModalBody {
    height: 100%;
    margin-top: -5.5rem;
    margin-bottom: 0;

    .GoPreviousLink {
      position: absolute;
      left: 48px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);

      &:active {
        transform: translateY(-50%) rotate(180deg) scale(0.98);
      }
    }

    .GoNextLink {
      position: absolute;
      right: 48px;
      top: 50%;
      transform: translateY(-50%);

      &:active {
        transform: translateY(-50%) scale(0.98);
      }
    }

    .GoPreviousLink,
    .GoNextLink {
      overflow: visible;

      &:hover {
        cursor: pointer;

        & > path:nth-child(1) {
          fill: $off-white-3;
        }
      }
    }
  }
}

.ModalInsight {
  display: flex;
  height: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  & > div {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 250px;
  }

  .ModalInsightText {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 44px;

    .ModalInsightLocationDate {
      color: $grey-4;
      font-size: 14px;
    }

    .ModalInsightTextCentered {
      .ModalInsightTitle {
        color: $black-1;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .ModalInsightContent {
        text-align: left;
        color: $black-1;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5em;
        margin-top: 0.5em;

        p {
          margin: 0;
          margin-top: 24px;
        }

        .InsightLink {
          margin-top: 10px;

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }

            svg {
              height: 24px;
              width: 24px;
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }

        .highlight-location {
          display: inline-block;
          font-size: 0.67em;
          margin-top: 2em;

          &:first-child {
            margin-top: 0;
          }
        }

        .highlight-value {
          display: inline-block;
          font-size: 1.66em;
          line-height: 0.75em;
          margin-top: 0.8em;
        }

        .highlight-label + br + .highlight-value {
          margin-top: 0.4em;
        }

        .highlight-label + br + .highlight-location {
          margin-top: 1em;
        }

        .highlight-location + br + .highlight-value {
          margin-top: 0.075em;
        }

        .highlight-label {
          display: inline-block;
          font-size: 0.67em;
          line-height: 1.875em;
          margin-left: 0.3125em;
        }

        .number-highlight {
          white-space: nowrap;
        }

        .footnote {
          display: inline-block;
          font-size: 0.67em;
          line-height: 1.5em;
          margin-top: 0.75em;
        }

        .individual-link-1,
        .individual-link-2 {
          display: block;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          svg {
            height: 24px;
            width: 24px;
            margin-right: 10px;
            vertical-align: middle;
          }
        }

        .individual-link-1 {
          margin-top: 25px;
        }

        .individual-link-2 {
          margin-top: 20px;
        }

        .waste-map-link,
        .leaderboard-link {
          display: inline-block;
          font-size: 14px;
          line-height: 20px;
          margin-top: 24px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          svg {
            height: 16px;
            width: 16px;
            margin-left: 5px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .ModalInsightGraph {
    background-color: $grey-1;
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    text-align: center;

    &.isWithGreenBackground {
      background: radial-gradient(
          69.49% 200.93% at 27.26% 52.38%,
          rgba(48, 128, 130, 0.5) 0%,
          rgba(94, 160, 162, 0) 100%
        ),
        linear-gradient(256.39deg, #89c4cc 7.91%, #5da0a2 77.87%);
    }

    .ModalInsightGraphCenter {
      flex-grow: 1;
      display: flex;

      .ModalInsightGraphContainer {
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 50%;

        img {
          margin: auto;
          max-width: 340px;
          width: 100%;
        }

        .HfbShareComparisonChart {
          margin: auto;

          .SideBySideComparisonBarChart {
            margin-bottom: 24px;
          }
        }

        .ModalInsightHorizontalComparisonBarChart {
          width: 320px;
        }

        .ModalInsightVerticalComparisonBarChart {
          width: 100px;
        }

        .PercentageBarChart {
          flex: 1;
        }
      }
    }

    .ModalInsightGraphTop,
    .ModalInsightGraphBottom {
      .ModalInsightLegend {
        font-size: 16px;

        .SimpleLegendDot {
          height: 18px;
          width: 18px;
          margin-top: -2px;
          margin-right: 8px;
        }
      }

      .WhatIfLink {
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        max-width: 260px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        svg {
          height: 18px;
          vertical-align: middle;
        }
      }
    }
  }

  .RoundedImage {
    border-radius: 50%;
  }
}

.InstorePage {
  .ModalInsight {
    .ModalInsightLocationDate {
      color: black;
      font-size: 24px !important;
      font-weight: bold;
      left: 100px;
      top: 87px;
      margin-top: 0;
    }

    .ModalInsightTitle {
      display: none;
    }

    .ModalInsightTextCentered {
      padding: 0 100px;
    }

    .ModalInsightContent {
      font-size: 48px !important;
    }

    .ModalInsightGraph {
      padding-bottom: 60px;
    }

    .ModalInsightGraphBottom {
      .ModalInsightLegend {
        font-size: 22px !important;

        .SimpleLegendDot {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

.ModalInsightLegend {
  display: flex;
  justify-content: center;
}

.SelectSlide {
  display: flex;
  justify-content: center;
  padding: 0;

  li {
    list-style-type: none;
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    button {
      display: block;
      height: 8px;
      width: 8px;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: $off-white-1;
      cursor: pointer;
    }

    &.Active button {
      background-color: $black;
    }
  }
}

.SelectInsightAndFeedback {
  display: flex;

  .SelectSlide {
    align-self: center;
  }

  .ModalInsightFeedback {
    font-size: 14px;
    margin-left: 48px;

    &:first-child {
      margin-left: 0;
    }

    .ThumbsUp,
    .ThumbsDown {
      vertical-align: middle;
      margin-left: 8px;

      &:hover {
        cursor: pointer;
      }

      &.isActive {
        circle {
          fill: $blue;
        }

        path {
          fill: white;
          stroke: transparent;
        }
      }
    }

    .ThumbsUp:active {
      transform: scale(0.98);
    }

    .ThumbsDown {
      transform: rotate(180deg);

      &:active {
        transform: rotate(180deg) scale(0.98);
      }
    }
  }
}

.Header {
  text-align: left;
  margin-bottom: 0;
}

.Values {
  margin-top: 0.5em;
  text-align: left;

  .LocationRow {
    font-size: 0.67em;
    margin-bottom: -1em;
  }

  .ValueRow {
    margin-top: 0.67em;
    margin-bottom: 0;
  }

  .Large {
    font-size: 1.66em;
  }

  .Small {
    font-size: 0.85em;
    margin-left: 0.3125em;
  }

  .Grey {
    color: $grey-5;
  }

  .ArrowContainer {
    margin-top: 4px;
  }
}

.WasteSankeyLink {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-decoration: none;
  text-align: left;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
  }
}

.SmallestContributorsGraph {
  width: 100%;
}

.SmallestOrLargestContributorsText,
.SmallestOrLargestContributorsListText {
  text-align: left;
}
