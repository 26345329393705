@import '../../Mixins.scss';
@import '../../Colours.module.scss';

.FilterDropdownMenu {
  background-color: $white;
  display: flex;
  align-items: center;

  .DropdownLabel {
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $grey-4;
    padding: 0 24px;
  }

  .DropdownOptionsMenu {
    font-size: 12px;
    border-radius: 999px;
    flex: 1;
    background-color: $grey-1;
    height: 44px;
    padding: 10px;

    .DropdownOptionsMenu__menu {
      border-radius: 22px;
      overflow: hidden;
    }

    .DropdownOptionsMenu__control {
      background-color: $grey-1;

      .DropdownOptionsMenu__value-container {
        .DropdownOptionsMenu__single-value {
          max-width: calc(100% - 40px) !important;
        }
      }
    }

    .DropdownOptionsMenu__indicators {
      height: 16px;
    }
  }

  &.graph {
    .DropdownOptionsMenu {
      width: 300px;

      .DropdownOptionsMenu__menu {
        overflow: visible;
      }

      .DropdownOptionsMenu__menu-list {
        overflow-y: visible;
      }
    }
  }

  &.topLevelGraph {
    width: 350px;

    .FilterOption {
      .Label {
        font-weight: normal;
      }

      .PName {
        display: none;
      }
    }
  }

  &.rangeFrom,
  &.rangeTo {
    .DropdownOptionsMenu {
      width: 150px;
    }
  }

  .GraphGroup {
    position: relative;
    overflow: visible;
    padding: 8px 24px;
    height: 32px;
    line-height: 32px;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    &:first-child:hover {
      border-radius: 22px 22px 0 0;
    }

    &:last-child:hover {
      border-radius: 0 0 22px 22px;
    }

    svg {
      position: absolute;
      top: 16px;
      right: 24px;
    }

    .GraphGroupSubmenu {
      display: none;
      position: absolute;
      right: -301px;
      width: 300px;
      transform: translateY(-40px);
      background: white;
      box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
      border-radius: 0 22px 22px 22px;
      overflow: hidden;

      &.isActive {
        display: block;
      }
    }
  }
}

.invalidate {
  &.selected_value,
  &.header_title {
    color: $red;
  }

  &.FilterDropdownMenu .controlicon {
    background-image: url(../../images/CircleArrow/Red.svg);
  }
}

.FilterOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  .Label {
    font-size: 12px;
    line-height: 24px;

    &.bold {
      font-weight: bold;
    }
  }

  .Badge {
    @include badge;

    background-color: $blue;
    line-height: initial;
  }
}
