.SkapaTooltip {
  margin-left: auto;
  span:focus {
    outline: none;
  }

  [role='tooltip'] {
    font-size: 10px;
  }
}
