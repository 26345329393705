@import '../Colours.module.scss';

.SkapaModal {
  .modal-header {
    padding-left: 96px;

    .modal-header__title-wrapper {
      text-align: start;
      margin-left: 0;
    }
  }

  .HeaderImage {
    width: 100%;
  }

  .Body {
    font-size: 14px;
    line-height: 22px;

    .FunctionSelectorTip {
      border: 1px solid $off-white-1;
      border-radius: 4px;
      height: 60px;
      line-height: 60px;
      margin-top: 60px;
      padding: 0 16px;

      svg {
        width: 16px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }

    .UnrankedReason {
      color: $red;
    }
  }

  .SiteOrCountryInfoBody {
    margin: auto;
    width: 300px;

    .SiteOrCountryInfoHeader {
      color: $grey-4;
      font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
      font-size: 12px;
      letter-spacing: 0.2px;
      line-height: 19px;
      margin-top: 50px;
      text-transform: uppercase;

      a:hover {
        color: $black;
      }

      .LinkIndicator {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 6px;
        background-image: url('../images/CircleArrow/Grey.svg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        vertical-align: middle;
      }

      .SiteOrCountryInfoTooltip {
        text-transform: none;
      }
    }

    .SiteOrCountryInfoValue {
      font-size: 28px;
      font-weight: bold;
      line-height: 32px;
      margin-top: 12px;

      .SiteOrCountryInfoUnit {
        font-size: 10px;
        line-height: 16px;
        margin-left: 10px;
        vertical-align: top;
        text-transform: uppercase;
      }
    }
  }

  .LearnMoreBody {
    padding-left: 96px;
    padding-right: 96px;
    margin-bottom: 100px;

    .IntroParagraph {
      margin: 32px 0 64px;
      font-size: 16px;
      line-height: 26px;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
    }

    h2 {
      font-size: 22px;
      margin-bottom: 11px;
    }

    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0px;
      margin: 32px 0 24px;
    }

    h4 {
      color: black;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      margin: 24px 0 16px;
    }

    h4 + p {
      margin-top: 0;
    }

    img {
      margin: 22px 0;
      max-width: 100%;
    }

    .PerformanceComponents {
      display: inline-block;
      list-style-position: inside;
      margin: 0;

      li {
        margin-bottom: 22px;

        &::marker {
          font-weight: bold;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .KpiLearnMoreBody {
    text-align: left;
    margin-left: 48px;
    margin-right: 48px;

    .IntroParagraph {
      font-size: 16px;
      line-height: 26px;
    }

    img {
      margin: 22px 0;
      max-width: 100%;

      &.NeighbourhoodImage {
        max-width: 50%;
        margin: 22px auto;
        display: flex;
      }
    }

    .PppProductsInfo {
      margin-top: 40px;

      ol {
        padding-left: 1em;
      }
    }

    .RepresentativeExamplesHeader {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .TableImage {
      width: 100%;
      margin-bottom: 40px;
    }

    .TwoColumn {
      columns: 2;
      column-gap: 40px;

      p:first-child {
        margin-top: 0;
      }

      h3 + p {
        margin-top: 7px;
      }
    }

    .TopicsInFocusHeader {
      border-bottom: 2px solid $off-white-1;
      font-size: 20px;
      margin-top: 50px;
      padding-bottom: 8px;
    }

    .TopicsInFocus {
      margin-top: 20px;
      columns: 3;

      ul {
        margin-top: 0;
        list-style-type: none;
        padding-inline-start: 0;
      }
    }

    .ListWithHeading {
      display: grid;
      grid-template-columns: 50% 50%;
      row-gap: 32px;
      text-align: left;
      border-top: 1px solid $off-white-1;
      border-bottom: 1px solid $off-white-1;
      padding: 64px 0 48px 0;
      margin: 48px 0 32px 0;

      ul {
        margin-top: 0;
      }
    }

    .DataSource {
      text-align: center;
      margin-top: 20px;
      font-size: 12px;
    }
  }

  .BenchDownloadBody {
    width: 426px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .BenchDownloadContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21.99px;
      text-align: left;
      border: 1px solid #dfdfdf;
      padding: 24px;
      color: #000000;
      cursor: pointer;
      background: none;

      > span:first-child {
        font-weight: 900;
      }
    }
  }

  .LastUpdated {
    margin-top: 32px;
  }

  a.Underlined {
    text-decoration: underline;
  }

  img.Centered {
    display: block;
    margin: 32px auto;
  }

  .ActionsImpacting {
    .ActionsImpacting-headers-site,
    .ActionsImpacting-headers-country,
    .ActionsImpacting-bottom-site,
    .ActionsImpacting-bottom-country {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 700;
      margin-left: 15px;
      border-bottom: 1px solid #cccccc;
      padding-bottom: 15px;
    }

    .ActionsImpacting-headers-site {
      margin-bottom: 15px;

      > div:nth-child(1) {
        width: 15%;
      }

      > div:nth-child(2) {
        width: 35%;
      }

      > div:nth-child(3) {
        width: 20%;
      }

      > div:nth-child(4) {
        width: 10%;
      }

      > div:nth-child(5) {
        width: 10%;
      }

      > div:nth-child(6) {
        width: 10%;
        text-align: end;
      }
    }

    .ActionsImpacting-headers-country,
    .ActionsImpacting-bottom-country {
      margin: 0 0 15px 0;

      > div:nth-child(1) {
        padding-left: 35px;
        width: 60%;
      }

      > div:nth-child(2),
      > div:nth-child(3),
      > div:nth-child(4) {
        width: 15%;
        text-align: end;
        margin-right: 10px;
      }

      > div:nth-child(4) {
        width: 10%;
      }
    }

    .container,
    .containerWithSub {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      height: 50px;
      padding-left: 15px;

      .emptyIcon {
        margin-left: 0;
        width: 24px;
        height: 24px;
      }
    }

    .container {
      > div:nth-child(1) {
        width: 15%;
      }

      > div:nth-child(2) {
        width: 35%;
        margin-right: 1%;
      }

      > div:nth-child(3) {
        width: 20%;
      }

      > div:nth-child(4) {
        width: 10%;
      }

      > div:nth-child(5) {
        width: 10%;
        text-align: end;
      }

      > div:nth-child(6) {
        width: 10%;
        text-align: end;
      }
    }

    .containerWithSub {
      flex-direction: column;
      height: auto;
      width: 100%;
      padding-left: 0;

      .withSubMain {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        width: 100%;

        .withSubMainArrow {
          display: flex;
          flex-direction: row;

          > :nth-child(2) {
            margin-left: 10px;
          }
        }

        > div:nth-child(1) {
          width: 60%;
        }

        > div:nth-child(2),
        > div:nth-child(3),
        > div:nth-child(4) {
          width: 15%;
          text-align: end;
          margin-right: 10px;
        }

        > div:nth-child(4) {
          width: 10%;
        }
      }

      .subMenu-container {
        width: 97%;
        background: #f5f5f5;
        padding: 20px;

        .subMenu-list-global-header,
        .subMenu-list-header {
          display: flex;
          font-weight: 700;
          width: 100%;
        }

        .subMenu-list-header,
        .subMenu-list {
          flex-direction: row;
          align-items: flex-start;

          .subMenu {
            align-self: start;
            padding: 7px 0;
          }

          > div:nth-child(1) {
            width: 12%;
            padding-left: 25px;
          }

          > div:nth-child(2) {
            width: 33%;
          }

          > div:nth-child(3) {
            width: 15%;
          }

          > div:nth-child(4) {
            width: 15%;
            text-align: start;
          }

          > div:nth-child(5) {
            width: 10%;
            text-align: end;
          }

          > div:nth-child(6) {
            width: 10%;
            text-align: end;
          }
        }

        .subMenu-list-global-header,
        .subMenu-list-global {
          display: flex;
          flex-direction: row;
          padding: 10px 0;

          > div:nth-child(1) {
            width: 60%;
            padding-left: 25px;
          }

          > div:nth-child(2) {
            width: 15%;
            text-align: end;
          }

          > div:nth-child(3) {
            width: 15%;
            text-align: end;
          }

          > div:nth-child(4) {
            width: 10%;
            text-align: end;
          }
        }

        .subMenu-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }
      }
    }

    .hide {
      display: none;
    }

    .ActionsImpacting-bottom-site {
      border-bottom: none;
      border-top: 1px solid #cccccc;
      justify-content: flex-end;
      margin-top: 15px;
      padding-top: 15px;

      > div:nth-child(2),
      > div:nth-child(3) {
        width: 10%;
        text-align: end;
      }

      > div:nth-child(2) {
        margin-left: auto;
      }
    }

    .ActionsImpacting-bottom-country {
      border-bottom: none;
      border-top: 1px solid #cccccc;
      padding-top: 15px;

      > div:nth-child(1) {
        width: 60%;
        padding-left: 25px;
      }

      > div:nth-child(2) {
        width: 15%;
        text-align: end;
        visibility: visible;
      }

      > div:nth-child(3) {
        width: 15%;
        text-align: end;
      }

      > div:nth-child(4) {
        width: 10%;
        text-align: end;
      }
    }
  }

  .analytics-tools-modal {
    border-top: 1px solid #dfdfdf;

    .analytics-tools-modal__container {
      display: flex;
      flex-direction: column;

      .analytics-tools-modal__header {
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #767676;
      }

      .analytics-tools-modal__item {
        width: 100%;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dfdfdf;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        cursor: pointer;
        transition: background-color 0.2s ease;
        color: #111111;

        &:hover {
          text-decoration: underline;
        }

        .analytics-tools-modal:hover {
          background-color: #f5f5f5;
        }

        .analytics-tools-modal:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
