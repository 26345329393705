@import '../../Colours.module.scss';

.WaterEfficiencySlide {
  .LegendItems {
    & > div:nth-child(2) {
      .LegendIcon {
        background-color: $off-white-4;

        path {
          display: none;
        }
      }
    }
    .Item {
      .LegendIcon {
        background-color: $off-white-4;

        path {
          display: none;
        }
      }
    }
  }
}
