@import '../../Colours.module.scss';

.NoDataView {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  height: 500px;

  .Image {
    padding-right: 35px;
  }

  .SubTitle {
    font-weight: bold;
    font-size: 14px;
    color: $grey-4;
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 10px;
    letter-spacing: 1px;
  }

  .Headline {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    width: 304px;
  }

  .Text {
    color: $grey-4;
    font-size: 14px;
    line-height: 24px;
    width: 304px;
    padding-top: 27px;

    a {
      text-decoration: underline;
    }
  }
}

.NoDataViewSmall {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
  margin: 0;
  height: auto;

  .Image {
    align-self: center;
    width: 120px;
    height: 80px;
  }

  .Text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    max-width: 280px;
    font-size: 14px;
    margin-right: 16px;

    .Headline {
      font-weight: bold;
      margin-bottom: 14px;
    }
  }
}
