.BenchmarkingAndGoals {
  &.SocialImpactBenchmarking {
    .SkapaTooltip {
      .tooltip__body {
        white-space: pre-wrap;
        max-width: 31vw;
      }
    }
  }
}
