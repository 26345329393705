.WasteTreatmentSlide {
  .InStoreSlideRight {
    .Legend {
      .LegendItems {
        display: block;
      }
    }
  }

  .graphContainer {
    .headingAndMessage {
      .Control {
        margin-left: auto;
        margin-right: 64px;
      }
    }
  }
}
